import { Checkbox, FormControlLabel, Grid, TextField, Select, MenuItem, Button, IconButton, InputLabel, FormControl, Tooltip, Typography, Switch, Slider, Radio, RadioGroup, CircularProgress, InputAdornment, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import brokerImage from "./images/broker_couple_1.jpg";
import { useHistory } from 'react-router-dom';

const formatPhoneNumber = (input) => {
    input = ('' + input).replace(/\D/g,'');
          
    input = input.substring(0,10);
  
    var size = input.length;
    if(size === 0){
            input = input;
    }else if(size < 4){
            input = '('+input;
    }else if(size < 7){
            input = '('+input.substring(0,3)+') '+input.substring(3,6);
    }else{
            input = '('+input.substring(0,3)+') '+input.substring(3,6)+' - '+input.substring(6,10);
    }
    return input; 
  }


  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your first name')
      .required('First Name is required'),
    lastName: yup
      .string('Enter your last name')
      .required('Last Name is required'),
    phone: yup
      .string('Enter your Home Phone Number')
      .min(16,'Enter your 10 digit Home Phone Number')
      .max(16,'Enter your 10 digit Home Phone Number')
      .required('Home Phone Number is required'),
    email: yup
      .string('Enter your Email Address')
      .required('Email Address is required'),
    firmName: yup
      .string('Enter name of Firm or Brokerage')
      .required('Firm name is required'),  
  });

  const getWidth = () => {
    var kk = window.innerWidth;

    if (kk > 900)
    {
      return 900;
    }

    if (kk < 400)
    {
      return 400;
    }

    return kk;
  };

const AgentLead = () => {    
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [width, setWidth] = React.useState(getWidth());
  const history = useHistory();

  useEffect(() => {
      window.scrollTo({top: 0, behavior:"smooth"});
  }, []);
  
  
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      firmName: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {    
      setSubmitting(true);  
      //let url = process.env.REACT_APP_BASE_URL + '/api/v2/Leads?code='+ process.env.REACT_APP_API_KEY;
      //console.log(values);
      
      document.cookie = 'firstName=' + encodeURIComponent(values.firstName) + '; max-age=28800;';
      document.cookie = 'lastName=' + encodeURIComponent(values.lastName) + '; max-age=28800;';
      document.cookie = 'phone=' + encodeURIComponent(values.phone) + '; max-age=28800;';
      document.cookie = 'email=' + encodeURIComponent(values.email) + '; max-age=28800;';
      document.cookie = 'firm=' + encodeURIComponent(values.firmName) + '; max-age=28800;';

      history.push("/agents/faqs");
    },
  });  

  return (
      <React.Fragment>
      <div style={{ marginTop: '32px', marginBottom: '32px'}}>
          <Grid container spacing={2} style={{ width: width, marginLeft: 'auto', marginRight: 'auto' }} >
              <Grid item xs={12} style={{ fontSize: '32px', display: 'flex', justifyContent: 'center', textAlign: 'center', padding: 0 }}>
                <strong>We just need a little more info</strong>
              </Grid>
              <Hidden smDown>
                <Grid item md={5}>
                  <img src={brokerImage} alt="Broker" width="343" style={{paddingTop: '24px'}} />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={7} style={{ fontSize: '16px' }}>
                  <p><strong>Thanks for your interest in MyBrokerMatch.com. Please provide your information below and we will redirect you to our REALTOR® Lead Program Overview.</strong></p>
                  <p><strong>We look forward to sharing the capabilities of our platform with you and what makes our leads different.</strong></p>
                  <p><strong>See you on the other side!</strong></p>
              </Grid>
          </Grid>
      </div>
      <div style={{ marginTop: '32px', marginBottom: '32px'}}>
        {!submitted &&
        <form onSubmit={formik.handleSubmit} noValidate="noValidate">
          <Grid container spacing={2} style={{ width: width, marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.phone}
                onChange={(e, value) => { formik.setFieldValue('phone', formatPhoneNumber(e.target.value));}}
                error={formik.touched.phone && Boolean(formik.errors.phone)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="email"
                name="email"
                label="Email Address"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="firmName"
                name="firmName"
                label="Name of Firm"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.firmName}
                onChange={formik.handleChange}
                error={formik.touched.firmName && Boolean(formik.errors.firmName)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Button variant='contained' color='primary' type="submit" disabled={submitting}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        }
      </div>

      </React.Fragment>
  );

}

export default AgentLead;