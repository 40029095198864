import React, { useState, useEffect } from "react";
import agentVideo from "./videos/Agent.mp4";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Accordion, AccordionSummary, Container, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import poster from "./images/video-poster.jpg";
import faqbg from "./images/bg_faqs_1.jpg";


const AgentFAQ = ({ loginWithRedirect, email }) =>
{

    useEffect(() => {
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

  const [expanded, setExpanded] = React.useState(false);  

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <div id="agentfaq" style={{ marginTop: '32px', marginBottom: '32px'}}>
          <Grid container spacing={4} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid item xs={12} sm={5}>
                  <video width="343" controls loop muted autoPlay poster={poster} onClick={(e) => e.target.muted = !e.target.muted}>
                      <source src={agentVideo} type="video/mp4" />
                  </video>
                  <Button onClick={() => loginWithRedirect({ screen_hint: 'signup', login_hint: email })} variant="contained" style={{ width: '100%', borderColor: 'white', borderWidth: '3px', borderStyle: 'solid', color: 'white', backgroundColor: '#F15A29' }}>
                  GET STARTED NOW
                  </Button>
              </Grid>
              <Grid item xs={12} sm={7} style={{ fontSize: '19px' }}>
                <Typography variant="h5">Additional Information for Brokers (Real Estate Professionals)</Typography>
                  <ol>
                    <li><Typography>In order to be accepted into the program, you must have 12 months or 12 transactions of experience.</Typography></li>
                    <li><Typography>If you are part of a team, only the person who individually matched with the client may work with that client.</Typography></li>
                    <li><Typography>In addition to the subscription fee ($9.95/month or $99.95 for a prepaid year), you will pay MyBrokerMatch, LLC a 35% referral fee on the side(s) of the transaction(s) that are specifically referred to you and closed.</Typography></li>
                    <li><Typography>All participating brokers (Real Estate Professionals) agree to MyBrokerMatch’s Letter of Expectation (below).</Typography></li>
                  </ol>
                <Typography variant="h5">MyBrokerMatch Letter of Expectation</Typography>
                  <br/>
                  <Typography>As a real estate professional and a participant in MyBrokerMatch, I will:</Typography>
                  <ol>
                    <li><Typography>Make initial contact with matched clients within 24 hours of assignment.</Typography></li>
                    <li><Typography>Always maintain myself in a professional manner, being especially sensitive to the expectations of the client as indicated on their profile.</Typography></li>
                    <li><Typography>Always dress accordingly as stated on the client’s profile.</Typography></li>
                    <li><Typography>Always be on time.</Typography></li>
                  </ol>
              </Grid>
          </Grid>
      </div>
      <div id="faq" style={{ backgroundImage: `url(${faqbg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', paddingTop: "16px", paddingBottom: "16px", backgroundAttachment: "fixed"}}>
        <Container width='900px'>              
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>
                    Why use MyBrokerMatch.com
                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>Wouldn’t it be great to work with a client with whom you’re compatible in many ways? Having a detailed profile of a client before you meet him/her helps “break the ice.” MyBrokerMatch.com does that and much more. While services that generate leads are fine, a better use of a matching website is to connect the REALTOR® with a customer who has chosen to work with them. You will save time and effort and have a more positive result for both parties, by knowing a bit about each other before you meet.
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>How does MyBrokerMatch.com match a REALTOR® with a client?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>The REALTOR® and client each fill out a questionnaire that identifies personality traits, work habits, preferences, likes and dislikes, and other factors. Our proprietary system then evaluates the compatibility of the client and REALTOR® to assign a solid match.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What happens next?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>After the client and REALTOR® are notified of the match, the REALTOR® contacts the client. From that point forward, the REALTOR® will log into the system periodically and update the status of the transaction.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What does it cost?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>For REALTORS®, the cost is $9.95/month plus a referral fee - 35% of the commission for transactions referred by the program. Often, there is a coupon code for new subscribers that waives the monthly subscription fee.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>After I pay the referral fee, is the customer mine to keep?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>Generally, yes. Whatever business is revealed to the REALTOR® at the time of the match is the transaction on which you will pay a referral fee. Occasionally, a multiple-home request may be revealed, with a referral to be paid on each transaction. Future transactions, after the initial referrals from MyBrokerMatch.com, are not subject to our fees.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What if the client has to sell in one city and buy in another?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>Typically, those clients will be matched with two separate REALTORS® — one for each location.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What if I don’t want the match?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>If, for some reason, a REALTOR® needs to decline a match, it is easy to do during the original notification. Although declining opportunities may result in lost business, it is appropriate to admit to being too busy at that time to properly service an additional client.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>Can the client decline a match with me?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>While extremely rare, the client also has the right to decline a match with a particular REALTOR®.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>I am part of a team; can I still participate?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>Yes, you certainly may. However, the individual REALTOR® who is matched with a client must be the one who works with the client. Passing a client off to an assistant or team member could jeopardize a REALTOR®’s participation in the program.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What if more than one REALTOR® in an area has the same matching score with a particular client?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>While it shouldn’t happen often, more than one REALTOR® may have the same matching score. In that case, MyBrokerMatch.com uses an additional criteria algorithm to assign the client.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>              
          <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
              <AccordionSummary  
              expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                  <Typography style={{color: 'white'}}>What happens if I am not satisfied with the program?

                  </Typography>
              </AccordionSummary>
              <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                  <Typography style={{ color: 'white'}}>A REALTOR® may resign from the program at any time. Referral fees for successful transactions resulting from a match will still be owed, regardless of subscription status.
                    
                  </Typography>
              </AccordionDetails>
          </Accordion>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgentFAQ;