import React from "react"
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const Footer = () => {


    return (
        <React.Fragment>
            <footer style={{backgroundColor: '#1B75BB', padding: '32px'}}>
                <Typography variant="h6" align="center" gutterBottom style={{ color: 'white' }} >
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }}>Home</Link> | <Link to={'/contact'} style={{ textDecoration: 'none', color: 'white' }}>Contact Us</Link> | <Link to={'/disclosure'} style={{ textDecoration: 'none', color: 'white' }}>Master Disclosure/Privacy Policy</Link>
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p" style={{ color: 'white'}}>
                  © MyBrokerMatch.com 2023
                </Typography>
            </footer>
        </React.Fragment>
    )

}

export default Footer;