import React, { useState } from "react";
import slide1 from "./images/slide1.jpg";
import header1 from "./images/background_hero_all.jpg";
import Button from "@material-ui/core/Button";
import logo1 from "./images/logo.svg";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import MBMTheme from './MBMTheme';
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import poster from "./images/video-poster.jpg";
import clientVideo from "./videos/Client.mp4";
import faqbg from "./images/bg_faqs_1.jpg";
import Accordion from "@material-ui/core/Accordion";
import { AccordionSummary, Container, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClientLead from "./ClientLead";


const useStyles = makeStyles((theme) => ({
    heroButtons: {
      padding: theme.spacing(4),
    },
    heroCaption: {
      paddingTop: theme.spacing(12),
      width: '100%',
      maxWidth: 850,
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '50px',
      textShadow: '1px 1px 2px rgb(0 0 0 / 50%)',
      textAlign: 'center',
      fontWeight: 'bold'
    },
    heroParent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  
    }
  }))

const Home = ({ loginWithRedirect }) => {
    const classes = useStyles(MBMTheme);  

    return (
        <React.Fragment>
            {/* <div style={{ position: 'relative' }}>
                <Button style={{ position: 'absolute', top: '14%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white'}}
                  onClick={() => loginWithRedirect()}
                >
                    Login or Sign Up
                </Button>
                <img src={logo1} style={{ position: 'absolute', top: '64%', left: '50%', transform: 'translate(-50%, -50%)', width: '29%' }} />
                <img src={header1} style={{ width: '100%' }} />

            </div> */}
            <div style={{ backgroundImage: `url(${slide1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '500px', marginTop: '-51px' }}>
                <Grid container spacing={4} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <Grid item xs={12} style={{ marginTop: '32px'}} >
                        <Typography className={classes.heroCaption} >
                        Using the right REALTOR® minimizes the stress of making the largest purchase of your life.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: '32px', marginBottom: '32px'}}>
                <Grid container spacing={4} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid item xs={12} sm={5}>
                        <video controls width="343" loop muted autoPlay poster={poster} onClick={(e) => e.target.muted = !e.target.muted}>
                            <source src={clientVideo} type="video/mp4" />
                        </video>
                        <Button onClick={() => loginWithRedirect({ screen_hint: 'signup'})} variant="contained" style={{ width: '100%', borderColor: 'white', borderWidth: '3px', borderStyle: 'solid', color: 'white', backgroundColor: '#F15A29' }}>
                        FIND THE PERFECT REALTOR® NOW
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ fontSize: '19px' }}>
                        <p><strong>Geography and the agent’s financial success are not valid predictors of a positive real estate experience for the buyer or seller.</strong></p>
                        <p><strong>We employ a unique process that matches home buyers and sellers with the best REALTOR® for their transaction.</strong></p>
                        <p><strong>Our software compares points of data to predict compatibility for a positive buying or selling experience.</strong></p>
                        <p><strong>MyBrokerMatch.com collects information about your needs, opinions, and personality using a simple and easy-to-use questionnaire to compile the data necessary to make a successful match.</strong></p>        
                    </Grid>
                </Grid>
            </div>
            <div id="clientStart">
                <ClientLead />
            </div>
        </React.Fragment>
    )
}

export default Home;