import { Checkbox, FormControlLabel, Grid, TextField, Select, MenuItem, Button, IconButton, InputLabel, FormControl, Tooltip, Typography, Switch, Slider, Radio, RadioGroup, CircularProgress, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import { Link, useHistory } from "react-router-dom";
import Banner from './Banner';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DisclosureText from './DisclosureText';

const formatPhoneNumber = (input) => {
  input = ('' + input).replace(/\D/g,'');
        
  input = input.substring(0,10);

  var size = input.length;
  if(size === 0){
          input = input;
  }else if(size < 4){
          input = '('+input;
  }else if(size < 7){
          input = '('+input.substring(0,3)+') '+input.substring(3,6);
  }else{
          input = '('+input.substring(0,3)+') '+input.substring(3,6)+' - '+input.substring(6,10);
  }
  return input; 
}

const validationSchema = yup.object({
  firstName: yup
    .string('Enter your first name')
    .required('First Name is required'),
  lastName: yup
    .string('Enter your last name')
    .required('Last Name is required'),
  streetAddress: yup
    .string('Enter your Street Address')
    .required('Street Address is required'),
  city: yup
    .string('Enter your City')
    .required('City is required'),
  state: yup
    .string('Enter your State')
    .required('State is required'),
  zip: yup
    .string('Enter your ZIP Code')
    .min(5,'Enter your 5 digit ZIP Code')
    .max(5,'Enter your 5 digit ZIP Code')
    .required('ZIP Code is required'),
  homePhone: yup
    .string('Enter your Home Phone Number')
    .min(16,'Enter your 10 digit Home Phone Number')
    .max(16,'Enter your 10 digit Home Phone Number')
    .required('Home Phone Number is required'),
  workPhone: yup
    .string('Enter your Work Phone Number')
    .min(16,'Enter your 10 digit Work Phone Number')
    .max(16,'Enter your 10 digit Work Phone Number'),
  mobilePhone: yup
    .string('Enter your Mobile Phone Number')
    .min(16,'Enter your 10 digit Mobile Phone Number')
    .max(16,'Enter your 10 digit Mobile Phone Number'),
  isPro: yup.boolean(),
  licenseId: yup
    .string()
    .when('isPro', {
      is: true,
      then: yup.string().required('Enter your License ID')
    }),
  licenseState: yup
  .string()
  .when('isPro', {
    is: true,
    then: yup.string().required('Enter your License State')
  }),
  serviceRadius: yup
  .string()
  .when('isPro', {
    is: true,
    then: yup.string().required('Enter your Service Radius')
  }),
  firmName: yup
  .string()
  .when('isPro', {
    is: true,
    then: yup.string().required('Enter a Firm Name')
  }),
  buySell: yup
    .string()
    .when('isPro', {
      is: false,
      then: yup.string().required('You must select Buy, Sell, or Both.')
    }),
  buyingLocation: yup
    .object()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '0' || buySell === '2' ));},
      then: yup.object().required('Buying location is required?')
    }),
  buyingTimeframe: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '0' || buySell === '2' ))},
      then: yup.string().required('Timeframe is required.')
    }),
  buyingPricerange: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '0' || buySell === '2' ))},
      then: yup.string().required('Price range is required')
    }),
  buyingType: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '0' || buySell === '2' ))},
      then: yup.string().required('Property type is required')
    }),
  sellingLocation: yup
    .object()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '1' || buySell === '2' ))},
      then: yup.object().required('Selling location is required')
    }),
  sellingTimeframe: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '1' || buySell === '2' ))},
      then: yup.string().required('Timeframe is required.')
    }),
  sellingType: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '1' || buySell === '2' ))},
      then: yup.string().required('Property type is required')
    }),
  askingPrice: yup
    .string()
    .when(['isPro','buySell'], {
      is: (isPro,buySell) => { return (!isPro && (buySell === '1' || buySell === '2' ))},
      then: yup.string().required('Asking price is required')
    }),


});

const UserDetails = () => {
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const [readTerms, setReadTerms] = React.useState(false);
  const [optionsState, setOptionsState] = React.useState({ loading: true });
  const [propertyTypes, setPropertyTypes] = React.useState();
  const [timeframes, setTimeframes] = React.useState();
  const [buyingLocations, setBuyingLocations] = React.useState([]);
  const [buyingLocationA, setBuyingLocation] = React.useState('');
  const [buyingLocationsOpen, setBuyingLocationsOpen] = React.useState(false);
  const buyingLocLoading = buyingLocationsOpen && buyingLocations.length === 0;
  const [buyingLocSearch, setBuyingLocSearch] = React.useState('');
  const [sellingLocations, setSellingLocations] = React.useState([]);
  const [sellingLocation, setSellingLocation] = React.useState('');
  const [sellingLocationsOpen, setSellingLocationsOpen] = React.useState(false);
  const sellingLocLoading = sellingLocationsOpen && sellingLocations.length === 0;
  const [sellingLocSearch, setSellingLocSearch] = React.useState('');
  const [billingDlgOpen, setBillingDlgOpen] = React.useState(false);
  const [billingOpt, setBillingOpt] = React.useState('0');
  const [userId, setUserId] = React.useState();
  const [proDlgOpen, setProDlgOpen] = React.useState(false);
  const [termDlgOpen, setTermDlgOpen] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState();
  const history = useHistory();

  const handleProClose = () => {
    setProDlgOpen(false);
  }

  const stripe = window.Stripe(process.env.REACT_APP_PUBLIC_KEY);

  const handleClose = () => {
    setBillingDlgOpen(false);
  };

  const handleTermOpen = () => {
    setTermDlgOpen(true);
    setReadTerms(false);
  };
  const handleTermClose = () => {
    setTermDlgOpen(false);
    setReadTerms(false);
  };
  const handleTermAccept = () => {
    setTermDlgOpen(false);
    setReadTerms(true);
  };

  const handleCheckout = () => {
    if (promoCode === 'FREEMATCH2024' || promoCode === 'freematch2024' || promoCode === 'FreeMatch2024' || promoCode === 'Freematch2024' || promoCode === 'DEMO2024' || promoCode === 'Demo2024' || promoCode === 'demo2024')
    {      
      updateApiUser();
      history.push('/assessment');
    }
    else
    {
      let bUrl = !isPro ? process.env.REACT_APP_BASE_URL + '/api/CreateClientBilling?code='+ process.env.REACT_APP_API_KEY + '' : billingOpt === '1' ? process.env.REACT_APP_BASE_URL + '/api/CreateAgentYearlyBilling?code='+ process.env.REACT_APP_API_KEY + '' : process.env.REACT_APP_BASE_URL + '/api/CreateAgentMonthlyBilling?code='+ process.env.REACT_APP_API_KEY + '';
      axios.post(bUrl, { customerEmail: user.email, userId: userId, promoCode: promoCode })
        .then((result) =>
        {
          console.log('getsessionid', result);
  
          stripe.redirectToCheckout({sessionId: result.data.sessionId})
            .catch((error) => {
              console.log('handlecheckoutresulterror', error)
            });
        })
        .catch((error) =>
        {
          console.log('getsessioniderror', error);
          return;
        })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (buyingLocSearch !== '' && buyingLocSearch !== buyingLocationA)
      {
        setBuyingLocations([]);
        axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Locations/' + buyingLocSearch + '?code='+ process.env.REACT_APP_API_KEY + '').then((response => {
          console.log(response);
          setBuyingLocations(response.data);
      }));
    }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)

  }, [buyingLocSearch, buyingLocationA]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(sellingLocSearch, sellingLocation);
      if (sellingLocSearch !== '' && sellingLocSearch !== sellingLocation)
      {
        setSellingLocations([]);
        axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Locations/' + sellingLocSearch + '?code='+ process.env.REACT_APP_API_KEY + '').then((response => {
          console.log(response);
          setSellingLocations(response.data);
      }));
    }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)

  }, [sellingLocSearch, sellingLocation]);

  useEffect(() => {     

    const apiUrl = process.env.REACT_APP_BASE_URL + '/api/v2/options?code='+ process.env.REACT_APP_API_KEY + '';
    setShowLoading(true);
    axios.get(apiUrl).then((response) => {
      setPropertyTypes(response.data.propertyTypes);
      setTimeframes(response.data.timeframes);
      setOptionsState({ loading: false}); 
    });  

    if (apiUser)
    {
      setShowLoading(true);
      axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Users/Details/' + apiUser.id + '?code=' + process.env.REACT_APP_API_KEY + '')
      .then((response) =>
      {
        console.log(response);
        setIsPro(response.data.isPro);
        formik.setFieldValue('firstName', response.data.firstName ?? '');
        formik.setFieldValue('lastName', response.data.lastName ?? '');
        formik.setFieldValue('streetAddress', response.data.streetAddress ?? '');
        formik.setFieldValue('city', response.data.city ?? '');
        formik.setFieldValue('state', response.data.state ?? '');
        formik.setFieldValue('zip', response.data.zip ?? '');
        formik.setFieldValue('homePhone', formatPhoneNumber(response.data.homePhone) ?? '');
        formik.setFieldValue('workPhone', formatPhoneNumber(response.data.workPhone) ?? '');
        formik.setFieldValue('mobilePhone', formatPhoneNumber(response.data.mobilePhone) ?? '');

        if (response.data.isPro)
        {
          formik.setFieldValue('licenseId', response.data.licenseId ?? '');
          formik.setFieldValue('licenseState', response.data.licenseState ?? '');
          formik.setFieldValue('serviceRadius', response.data.serviceRadius ?? '');
          formik.setFieldValue('firmName', response.data.firmName ?? '');
          formik.setFieldValue('isMemberNAR', response.data.isMemberNar ?? false);
          formik.setFieldValue('isPro', true);
        }
        else
        {
          formik.setFieldValue('clientKnowledge', response.data.clientKnowledge ?? 1);
          formik.setFieldValue('clientComfort', response.data.clientComfort ?? 1);
  
          formik.setFieldValue('buyingLocation', response.data.buyingLocation ?? '');
          formik.setFieldValue('buyingTimeframe', response.data.buyingTimeframe ?? '');
          formik.setFieldValue('buyingPricerange', response.data.buyingPriceRange ?? '');
          formik.setFieldValue('buyingType', response.data.buyingType ?? '');
  
          formik.setFieldValue('sellingLocation', response.data.sellingLocation ?? '');
          formik.setFieldValue('sellingTimeframe', response.data.sellingTimeFrame ?? '');
          formik.setFieldValue('sellingType', response.data.sellingType ?? '');
          formik.setFieldValue('askingPrice', response.data.askingPrice ?? '');

          formik.setFieldValue('buySell', response.data.buySell ?? '');
        }

        setShowLoading(false);
      })
      .catch((error) => 
      {
        setShowLoading(false);
        console.log(error);
      });
    }

    setShowLoading(false);
  }, [setOptionsState, apiUser]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      homePhone: '',
      workPhone: '',
      mobilePhone: '',
      email: user.email,
      licenseId: '',
      licenseState: '',
      serviceRadius: '',
      firmName: '',
      isExperienced: false,
      agentComfort: 1,
      isMemberNAR: false,
      clientKnowledge: 1,
      clientComfort: 1,
      buyingLocation: '',
      buyingTimeframe: '',
      buyingPricerange: '',
      buyingType: '',
      sellingLocation: '',
      sellingTimeframe: '',
      sellingType: '',
      askingPrice: '',
      isPro: false,
      buySell: '0'

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isPro && (formik.values.agentComfort < 3 || !formik.values.isExperienced || !formik.values.isMemberNAR))
      {
        console.log(isPro,formik.values.agentComfort,formik.values.isExperienced,formik.values.isMemberNAR);
        setProDlgOpen(true);
        return;
      };   
      
      let url = isPro ? process.env.REACT_APP_BASE_URL + '/api/v2/Users/CreateAgentUser?code='+ process.env.REACT_APP_API_KEY + '' : process.env.REACT_APP_BASE_URL + '/api/v2/Users/CreateClientUser?code='+ process.env.REACT_APP_API_KEY + '';
 
      axios({
        method: 'post',
        url: url,
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then((response) => 
      {
        setUserId(response.data.userid);
        setBillingDlgOpen(true);
      })
      .catch((error) =>
      {
        console.log(error);
      });
    },
  });  
  
  return (
    <React.Fragment>
      <Banner titleText="Complete Your Account" />
        <form onSubmit={formik.handleSubmit} noValidate="noValidate">
      <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          {isPro ?          
            <Typography variant="h6">If you are not a real estate professional, please <span onClick={() => { setIsPro(false); formik.setFieldValue('isPro', false);}} style={{ cursor: 'pointer', color: '#1B75BB', textDecoration: 'underline'}}>click here.</span></Typography> :
            <Typography variant="h6">If you are a real estate professional, please <span onClick={() => { setIsPro(true); formik.setFieldValue('isPro', true);}} style={{ cursor: 'pointer', color: '#1B75BB', textDecoration: 'underline'}}>click here</span></Typography>
          }
        </Grid>   
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="streetAddress"
            name="streetAddress"
            label="Street Address"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
            error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="city"
            name="city"
            label="City"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <FormControl style={{ width: '90%' }} variant="outlined">
            <InputLabel id="state-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.state && Boolean(formik.errors.state)}>State *</InputLabel>
            <Select
              id="state"
              name="state"
              labelId="state-input-label"
              variant="outlined"
              required
              style={{width: '100%'}}
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}   
            >
              <MenuItem value='' disabled><em>Select State</em></MenuItem>
              <MenuItem value='AL'>Alabama</MenuItem>
              <MenuItem value='AK'>Alaska</MenuItem>
              <MenuItem value='AZ'>Arizona</MenuItem>
              <MenuItem value='AR'>Arkansas</MenuItem>
              <MenuItem value='CA'>California</MenuItem>
              <MenuItem value='CO'>Colorado</MenuItem>
              <MenuItem value='CT'>Connecticut</MenuItem>
              <MenuItem value='DE'>Delaware</MenuItem>
              <MenuItem value='DC'>District of Columbia</MenuItem>
              <MenuItem value='FL'>Florida</MenuItem>
              <MenuItem value='GA'>Georgia</MenuItem>
              <MenuItem value='HI'>Hawaii</MenuItem>
              <MenuItem value='ID'>Idaho</MenuItem>
              <MenuItem value='IL'>Illinois</MenuItem>
              <MenuItem value='IN'>Indiana</MenuItem>
              <MenuItem value='IA'>Iowa</MenuItem>
              <MenuItem value='KS'>Kansas</MenuItem>
              <MenuItem value='KY'>Kentucky</MenuItem>
              <MenuItem value='LA'>Louisiana</MenuItem>
              <MenuItem value='ME'>Maine</MenuItem>
              <MenuItem value='MD'>Maryland</MenuItem>
              <MenuItem value='MA'>Massachusetts</MenuItem>
              <MenuItem value='MI'>Michigan</MenuItem>
              <MenuItem value='MN'>Minnesota</MenuItem>
              <MenuItem value='MS'>Mississippi</MenuItem>
              <MenuItem value='MO'>Missouri</MenuItem>
              <MenuItem value='MT'>Montana</MenuItem>
              <MenuItem value='NE'>Nebraska</MenuItem>
              <MenuItem value='NV'>Nevada</MenuItem>
              <MenuItem value='NH'>New Hampshire</MenuItem>
              <MenuItem value='NJ'>New Jersey</MenuItem>
              <MenuItem value='NM'>New Mexico</MenuItem>
              <MenuItem value='NY'>New York</MenuItem>
              <MenuItem value='NC'>North Carolina</MenuItem>
              <MenuItem value='ND'>North Dakota</MenuItem>
              <MenuItem value='OH'>Ohio</MenuItem>
              <MenuItem value='OK'>Oklahoma</MenuItem>
              <MenuItem value='OR'>Oregon</MenuItem>
              <MenuItem value='PA'>Pennsylvania</MenuItem>
              <MenuItem value='RI'>Rhode Island</MenuItem>
              <MenuItem value='SC'>South Carolina</MenuItem>
              <MenuItem value='SD'>South Dakota</MenuItem>
              <MenuItem value='TN'>Tennessee</MenuItem>
              <MenuItem value='TX'>Texas</MenuItem>
              <MenuItem value='UT'>Utah</MenuItem>
              <MenuItem value='VT'>Vermont</MenuItem>
              <MenuItem value='VA'>Virginia</MenuItem>
              <MenuItem value='WA'>Washington</MenuItem>
              <MenuItem value='WV'>West Virginia</MenuItem>
              <MenuItem value='WI'>Wisconsin</MenuItem>
              <MenuItem value='WY'>Wyoming</MenuItem>
              <MenuItem value='AS'>American Samoa</MenuItem>
              <MenuItem value='GU'>Guam</MenuItem>
              <MenuItem value='MP'>Northern Mariana Islands</MenuItem>
              <MenuItem value='PR'>Puerto Rico</MenuItem>
              <MenuItem value='VI'>U.S. Virgin Islands</MenuItem>
              <MenuItem value='FM'>Micronesia</MenuItem>
              <MenuItem value='MH'>Marshall Islands</MenuItem>
              <MenuItem value='PW'>Palau</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="zip"
            name="zip"
            label="ZIP Code"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={formik.touched.zip && Boolean(formik.errors.zip)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="homePhone"
            name="homePhone"
            label="Home Phone"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.homePhone}
            onChange={(e, value) => { formik.setFieldValue('homePhone', formatPhoneNumber(e.target.value));}}
            error={formik.touched.homePhone && Boolean(formik.errors.homePhone)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="workPhone"
            name="workPhone"
            label="Work Phone"
            variant="outlined"
            style={{width: '90%'}}
            value={formik.values.workPhone}
            onChange={(e, value) => { formik.setFieldValue('workPhone', formatPhoneNumber(e.target.value));}}
            error={formik.touched.workPhone && Boolean(formik.errors.workPhone)}  
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="mobilePhone"
            name="mobilePhone"
            label="Mobile Phone"
            variant="outlined"
            style={{width: '90%'}}
            value={formik.values.mobilePhone}
            onChange={(e, value) => { formik.setFieldValue('mobilePhone', formatPhoneNumber(e.target.value));}}
            error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}  
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <Tooltip title={user.email}>
            <TextField
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              required
              style={{width: '90%'}}
              disabled
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}  
            />
          </Tooltip>
        </Grid>   
        {isPro ?
          <React.Fragment>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="licenseId"
                name="licenseId"
                label="Real Estate License ID"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.licenseId}
                onChange={formik.handleChange}
                error={formik.touched.licenseId && Boolean(formik.errors.licenseId)}  
              />    
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl style={{ width: '90%' }} variant="outlined">
                <InputLabel id="license-state-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.licenseState && Boolean(formik.errors.licenseState)}>Real Estate License State *</InputLabel>
                <Select
                  id="licenseState"
                  name="licenseState"
                  labelId="license-state-input-label"
                  variant="outlined"
                  required
                  style={{width: '100%'}}
                  value={formik.values.licenseState}
                  onChange={formik.handleChange}
                  error={formik.touched.licenseState && Boolean(formik.errors.licenseState)}  
                >
                  <MenuItem value='' disabled><em>Select State</em></MenuItem>
                  <MenuItem value='AL'>Alabama</MenuItem>
                  <MenuItem value='AK'>Alaska</MenuItem>
                  <MenuItem value='AZ'>Arizona</MenuItem>
                  <MenuItem value='AR'>Arkansas</MenuItem>
                  <MenuItem value='CA'>California</MenuItem>
                  <MenuItem value='CO'>Colorado</MenuItem>
                  <MenuItem value='CT'>Connecticut</MenuItem>
                  <MenuItem value='DE'>Delaware</MenuItem>
                  <MenuItem value='DC'>District of Columbia</MenuItem>
                  <MenuItem value='FL'>Florida</MenuItem>
                  <MenuItem value='GA'>Georgia</MenuItem>
                  <MenuItem value='HI'>Hawaii</MenuItem>
                  <MenuItem value='ID'>Idaho</MenuItem>
                  <MenuItem value='IL'>Illinois</MenuItem>
                  <MenuItem value='IN'>Indiana</MenuItem>
                  <MenuItem value='IA'>Iowa</MenuItem>
                  <MenuItem value='KS'>Kansas</MenuItem>
                  <MenuItem value='KY'>Kentucky</MenuItem>
                  <MenuItem value='LA'>Louisiana</MenuItem>
                  <MenuItem value='ME'>Maine</MenuItem>
                  <MenuItem value='MD'>Maryland</MenuItem>
                  <MenuItem value='MA'>Massachusetts</MenuItem>
                  <MenuItem value='MI'>Michigan</MenuItem>
                  <MenuItem value='MN'>Minnesota</MenuItem>
                  <MenuItem value='MS'>Mississippi</MenuItem>
                  <MenuItem value='MO'>Missouri</MenuItem>
                  <MenuItem value='MT'>Montana</MenuItem>
                  <MenuItem value='NE'>Nebraska</MenuItem>
                  <MenuItem value='NV'>Nevada</MenuItem>
                  <MenuItem value='NH'>New Hampshire</MenuItem>
                  <MenuItem value='NJ'>New Jersey</MenuItem>
                  <MenuItem value='NM'>New Mexico</MenuItem>
                  <MenuItem value='NY'>New York</MenuItem>
                  <MenuItem value='NC'>North Carolina</MenuItem>
                  <MenuItem value='ND'>North Dakota</MenuItem>
                  <MenuItem value='OH'>Ohio</MenuItem>
                  <MenuItem value='OK'>Oklahoma</MenuItem>
                  <MenuItem value='OR'>Oregon</MenuItem>
                  <MenuItem value='PA'>Pennsylvania</MenuItem>
                  <MenuItem value='RI'>Rhode Island</MenuItem>
                  <MenuItem value='SC'>South Carolina</MenuItem>
                  <MenuItem value='SD'>South Dakota</MenuItem>
                  <MenuItem value='TN'>Tennessee</MenuItem>
                  <MenuItem value='TX'>Texas</MenuItem>
                  <MenuItem value='UT'>Utah</MenuItem>
                  <MenuItem value='VT'>Vermont</MenuItem>
                  <MenuItem value='VA'>Virginia</MenuItem>
                  <MenuItem value='WA'>Washington</MenuItem>
                  <MenuItem value='WV'>West Virginia</MenuItem>
                  <MenuItem value='WI'>Wisconsin</MenuItem>
                  <MenuItem value='WY'>Wyoming</MenuItem>
                  <MenuItem value='AS'>American Samoa</MenuItem>
                  <MenuItem value='GU'>Guam</MenuItem>
                  <MenuItem value='MP'>Northern Mariana Islands</MenuItem>
                  <MenuItem value='PR'>Puerto Rico</MenuItem>
                  <MenuItem value='VI'>U.S. Virgin Islands</MenuItem>
                  <MenuItem value='FM'>Micronesia</MenuItem>
                  <MenuItem value='MH'>Marshall Islands</MenuItem>
                  <MenuItem value='PW'>Palau</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl style={{ width: '90%' }} variant="outlined">
                <InputLabel id="service-radius-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.serviceRadius && Boolean(formik.errors.serviceRadius)}>Service Radius in Miles *</InputLabel>
                <Select
                  id="serviceRadius"
                  name="serviceRadius"
                  labelId="service-radius-label"
                  variant="outlined"
                  required
                  style={{width: '100%'}}
                  value={formik.values.serviceRadius}
                  onChange={formik.handleChange}
                  error={formik.touched.serviceRadius && Boolean(formik.errors.serviceRadius)}  
                >
                  <MenuItem value='' disabled><em>Select Radius</em></MenuItem>
                  <MenuItem value='10'>10</MenuItem>
                  <MenuItem value='20'>20</MenuItem>
                  <MenuItem value='50'>50</MenuItem>
                  <MenuItem value='100'>100</MenuItem>
                  <MenuItem value='200'>200</MenuItem>
                  <MenuItem value='500'>500</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="firmName"
                name="firmName"
                label="Name of Firm"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.firmName}
                onChange={formik.handleChange}
                error={formik.touched.firmName && Boolean(formik.errors.firmName)}  
              />    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography style={{ textAlign: 'center'}}>Have you been a real estate sales agent/broker for at least one year<br/>OR completed 12 or more transactions?</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px'}}>
              <Typography>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch 
                      id="isExperienced" 
                      name="isExperienced" 
                      value={formik.values.isExperienced}
                      onChange={formik.handleChange}
                      error={formik.touched.isExperienced && Boolean(formik.errors.isExperienced)}                        
                      />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography>On a scale of 1-5, how comfortable are you with the home buying/selling process?</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Slider
                id="agentComfort"
                defaultValue={3}
                step={1}
                marks={[{value: 1, label: 'Not Comfortable'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Comfortable'}]}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                style={{ width: '400px' }}
                value={formik.values.agentComfort}
                onChange={(e,value) => formik.setFieldValue('agentComfort', value)}
                error={formik.touched.agentComfort && Boolean(formik.errors.agentComfort)} 
              />              
            </Grid>           
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography>Are you a member of the National Association of REALTORS®</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px'}}>
              <Typography>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch 
                      id="isMemberNAR" 
                      name="isMemberNAR"
                      value={formik.values.isMemberNAR}
                      onChange={formik.handleChange}
                      error={formik.touched.isMemberNAR && Boolean(formik.errors.isMemberNAR)} 
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </Typography>
            </Grid>
          </React.Fragment> :
          <React.Fragment>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography>On a scale of 1-5, please rate your knowledge of the home buying/selling process?</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Slider
                id="clientKnowledge"
                name="clientKnowledge"
                defaultValue={3}
                step={1}
                marks={[{value: 1, label: 'Not Knowledgeable'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Knowledgeable'}]}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                style={{ width: '400px' }}
                value={formik.values.clientKnowledge}
                onChange={(e,value) => formik.setFieldValue('clientKnowledge', value)}
                error={formik.touched.clientKnowledge && Boolean(formik.errors.clientKnowledge)} 
              />              
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography>On a scale of 1-5, how comfortable are you with the home buying/selling process?</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Slider
                id="clientComfort"
                name="clientComfort"
                defaultValue={3}
                step={1}
                marks={[{value: 1, label: 'Not Comfortable'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Comfortable'}]}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                style={{ width: '400px' }}
                value={formik.values.clientComfort}
                onChange={(e,value) => formik.setFieldValue('clientComfort', value)}
                error={formik.touched.clientComfort && Boolean(formik.errors.clientComfort)} 
              />              
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
              <Typography>I would like to:</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px'}}>
              <RadioGroup name="client-options" row value={formik.values.buySell} onChange={(e) => formik.setFieldValue('buySell',e.target.value)} error={formik.touched.buySell && Boolean(formik.errors.buySell)}>
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Buy" />
                <FormControlLabel value="1" control={<Radio color="primary" />} label="Sell" />
                <FormControlLabel value="2" control={<Radio color="primary" />} label="Both" />
              </RadioGroup>
            </Grid>
            {Boolean(formik.errors.buySell) &&
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center' }}>
              <Typography style={{color: 'red'}}>You must select Buy, Sell, or Both.</Typography>
            </Grid>
            }
            {/* Buying */}
            {formik.values.buySell && (formik.values.buySell === "0" || formik.values.buySell === "2") &&
            <React.Fragment>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
                <Typography>Please tell us where you would like to buy.</Typography>    
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Autocomplete
                  id="buyingLocation"
                  name="buyingLocation"
                  open={buyingLocationsOpen}
                  onOpen={() => {
                    setBuyingLocationsOpen(true);
                  }}
                  onClose={() => {
                    setBuyingLocationsOpen(false);
                  }}
                  options={buyingLocations}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  loading={buyingLocLoading}
                  renderInput={(params) => <TextField {...params} label="Looking in City, State" variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {buyingLocLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={formik.touched.buyingLocation && Boolean(formik.errors.buyingLocation)} 
                  />}
                  style={{width: '90%'}}
                  value={formik.values.buyingLocation}
                  onChange={(e, value) => {formik.setFieldValue("buyingLocation", value); setBuyingLocation(value);} }
                  onInputChange={(e, value) => e && setBuyingLocSearch(value)}
                  error={formik.touched.buyingLocation && Boolean(formik.errors.buyingLocation)} 
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="buying-timeframe-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.buyingTimeframe && Boolean(formik.errors.buyingTimeframe)}>Select a Timeframe to buy</InputLabel>
                  <Select
                    id="buyingTimeframe"
                    name="buyingTimeframe"
                    labelId="buying-timeframe-input-label"
                    variant="outlined"
                    required
                    style={{width: '100%'}}
                    value={formik.values.buyingTimeframe}
                    onChange={formik.handleChange}
                    error={formik.touched.buyingTimeframe && Boolean(formik.errors.buyingTimeframe)}
                  >
                    <MenuItem value='' disabled><em>Select a Timeframe</em></MenuItem>
                    {!optionsState.loading && timeframes && timeframes.map((obj) => <MenuItem value={obj.id}>{obj.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="buying-pricerange-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.buyingPricerange && Boolean(formik.errors.buyingPricerange)}>Select a Price Range</InputLabel>
                  <Select
                    id="buyingPricerange"
                    name="buyingPricerange"
                    labelId="buying-pricerange-input-label"
                    variant="outlined"
                    required
                    style={{width: '100%'}}
                    value={formik.values.buyingPricerange}
                    onChange={formik.handleChange}
                    error={formik.touched.buyingPricerange && Boolean(formik.errors.buyingPricerange)}
                  >
                    <MenuItem value='' disabled><em>Select a Price Range</em></MenuItem>
                    <MenuItem value='0'>Less than $200,000</MenuItem>
                    <MenuItem value='1'>$200,000 to $400,000</MenuItem>
                    <MenuItem value='2'>$400,000 to $700,000</MenuItem>
                    <MenuItem value='3'>$700,000 to $1,000,000</MenuItem>
                    <MenuItem value='4'>$1,000,000 to $2,000,000</MenuItem>
                    <MenuItem value='5'>Greater than $2,000,000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="buying-type-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.buyingType && Boolean(formik.errors.buyingType)}>Select a Property Type</InputLabel>
                  <Select
                    id="buyingType"
                    name="buyingType"
                    labelId="buying-type-input-label"
                    variant="outlined"
                    required
                    style={{width: '100%'}}
                    value={formik.values.buyingType}
                    onChange={formik.handleChange}
                    error={formik.touched.buyingType && Boolean(formik.errors.buyingType)}      
                  >
                    <MenuItem value='' disabled><em>Select a Property Type</em></MenuItem>
                    {!optionsState.loading && propertyTypes && propertyTypes.map((obj) => <MenuItem value={obj.id}>{obj.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </React.Fragment>
            }
            {/* end buying */}
            {/* Selling */}
            {formik.values.buySell && (formik.values.buySell === "1" || formik.values.buySell === "2") &&
            <React.Fragment>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
                <Typography>Please tell us about the home you would like to sell.</Typography>    
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Autocomplete
                  id="sellingLocation"
                  name="sellingLocation"
                  open={sellingLocationsOpen}
                  onOpen={() => {
                    setSellingLocationsOpen(true);
                  }}
                  onClose={() => {
                    setSellingLocationsOpen(false);
                  }}
                  options={sellingLocations}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  loading={sellingLocLoading}
                  renderInput={(params) => <TextField {...params} label="Selling in City, State" variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {sellingLocLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={formik.touched.sellingLocation && Boolean(formik.errors.sellingLocation)} 
                  />}
                  style={{width: '90%'}}
                  value={formik.values.sellingLocation}
                  onChange={(e, value) => formik.setFieldValue("sellingLocation", value) && setSellingLocation(value) }
                  onInputChange={(e, value) => e && setSellingLocSearch(value)}
                  error={formik.touched.sellingLocation && Boolean(formik.errors.sellingLocation)} 
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="selling-timeframe-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.sellingTimeframe && Boolean(formik.errors.sellingTimeframe)}>Select a Timeframe to Sell</InputLabel>
                  <Select
                    id="sellingTimeframe"
                    name="sellingTimeframe"
                    labelId="selling-timeframe-input-label"
                    variant="outlined"
                    required
                    style={{width: '100%'}}
                    value={formik.values.sellingTimeframe}
                    onChange={formik.handleChange}
                    error={formik.touched.sellingTimeframe && Boolean(formik.errors.sellingTimeframe)}
                  >
                    <MenuItem value='' disabled><em>Select a Timeframe</em></MenuItem>
                    {!optionsState.loading && timeframes && timeframes.map((obj) => <MenuItem value={obj.id}>{obj.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <TextField
                  id="askingPrice"
                  name="askingPrice"
                  label="Asking Price"
                  variant="outlined"
                  style={{width: '90%'}}
                  value={formik.values.askingPrice}
                  onChange={formik.handleChange}
                  error={formik.touched.askingPrice && Boolean(formik.errors.askingPrice)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" error={formik.touched.askingPrice && Boolean(formik.errors.askingPrice)}>$</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="selling-type-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}} error={formik.touched.sellingType && Boolean(formik.errors.sellingType)}>Property Type</InputLabel>
                  <Select
                    id="sellingType"
                    name="sellingType"
                    labelId="selling-type-input-label"
                    variant="outlined"
                    required
                    style={{width: '100%'}}
                    value={formik.values.sellingType}
                    onChange={formik.handleChange}
                    error={formik.touched.sellingType && Boolean(formik.errors.sellingType)}
                  >
                    <MenuItem value='' disabled><em>Select a Property Type</em></MenuItem>
                    {!optionsState.loading && propertyTypes && propertyTypes.map((obj) => <MenuItem value={obj.id}>{obj.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </React.Fragment>
            }
            {/* End Selling */}
          </React.Fragment> 
        }  
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={readTerms}
                onChange={(e) => {setReadTerms(e.target.checked)}}
                name="terms_and_conds_check"
                color="primary"
              />
            }
            label={
              <Typography>
                I have read and agree to the <span style={{ color: '#1B75BB', textDecoration: "underline" }} onClick={handleTermOpen}>Terms and Conditions.</span>
              </Typography>              
            }
          />
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <Button variant='contained' color='primary' disabled={!readTerms} type="submit">
            Next
          </Button>
        </Grid>
          
      </Grid>
        </form>
      <Dialog onClose={handleClose} open={billingDlgOpen} fullWidth={false} maxWidth='md'>
        <DialogTitle style={{display: 'flex', justifyContent: 'center'}}>
          <Typography variant="h5" color="primary">Proceed to Checkout</Typography>
          <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: '500px', marginLeft: 'auto', marginRight: 'auto', marginTop: '12px', marginBottom: '24px' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography>Checkout is the last step before we move on to the personality assessment. If you have a coupon code, you may enter it now. Any adjustments will be made at checkout.</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <TextField
                  id="promoCode"
                  name="promoCode"
                  label="Coupon Code"
                  variant="outlined"
                  style={{width: '50%'}}
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
            </Grid>
            { isPro && <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography>Choose your MyBrokerMatch.com plan:</Typography>
            </Grid>}
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              {isPro ?
                <RadioGroup name="payment-radios" value={billingOpt} onChange={(e) => setBillingOpt(e.target.value)}>
                  <FormControlLabel value="0" control={<Radio color="primary" />} label="Subscribe for $9.95 billed monthly." />
                  <FormControlLabel value="1" control={<Radio color="primary" />} label="Subscribe for $99.95 billed annually." />
                </RadioGroup>
                :
                <Typography>For buyers and sellers there is a one time fee of $24.95</Typography>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center', marginBottom: '16px'}}>
          <Button variant='contained' color='primary' onClick={() => handleCheckout()}>
            Checkout
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleProClose} open={proDlgOpen} fullWidth={false} maxWidth='md'>
        <DialogTitle>
          <IconButton aria-label="close" onClick={handleProClose} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: '500px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography>We're sorry, this service is only available to experienced and confident members of the National Association of REALTORS®.</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleTermClose} open={termDlgOpen} fullWidth={true} maxWidth='md'>
        <DialogTitle style={{display: 'flex', justifyContent: 'center', marginTop: '16px'}}>
          <Typography variant="h5" color="primary">Terms and Conditions</Typography>
          <IconButton aria-label="close" onClick={handleTermClose} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DisclosureText />
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center', marginBottom: '16px'}}>
          <Button variant='contained' color='primary' onClick={handleTermAccept}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default UserDetails;