import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Banner from "./Banner";
import { Grid } from "@material-ui/core";

const AboutUs = () => {

    return (
        <React.Fragment>
            <div style={{ marginTop: '-51px' }} />
            <Banner titleText="About Us" />
            <Grid container spacing={4} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px' }}>
            <Grid item xs={12} spacing={4}>
                <Typography style={{ fontSize: '18px', textDecoration: 'none', color: '#3b3b3b'}}>
                <strong>Who We Are</strong><br />
                Mybrokermatch.com was launched in early 2023 after founders David Helms and Rick Epperson embarked on building a website that helps consumers have a pleasurable real estate experience. Studies have shown that many consumers don’t use the same real estate agent twice because they didn’t get along with the agent. Helms and Epperson have been in the real estate business since the 1980’s and they joined forces with an industry psychologist to help determine what criteria may predict an agent and client enjoying working together. The vision was to have a website that would act much like a dating site for real estate agents and clients. Most importantly, MyBrokerMatch.com is a platform where a buyer or seller can begin what is sometimes the most stressful transaction a person goes through and actually enjoy the experience. <br/><br/>
                <strong>What We Do</strong><br />
                Real estate agents sign up on Mybrokermatch.com and complete a questionnaire that highlights their personality. Clients sign up and complete a questionnaire that highlights their personality as well. Their information is carefully run through our proprietary algorithm to see which Realtor would be most compatible with the customers for a business transaction. Oftentimes agents are chosen just by their closing record or by referral, at MyBrokerMatch we know there’s a lot at stake in a real estate transaction and camaraderie and trust are of utmost importance. We feel many of the matches from this platform will result not only in a strong working relationship, but friendships as well!<br/><br/>
                <strong>Where We Are</strong><br />
                Mybrokermatch.com is headquartered in Winston Salem, North Carolina. We can be reached at <a style={{textDecoration: 'none', color: '#1B75BB'}} href="mailto:info@MyBrokerMatch.com">info@MyBrokerMatch.com</a> or 336-893-9928.<br />
                <br />
                </Typography>
            </Grid>
            </Grid>

        </React.Fragment>
    );
};

export default AboutUs;