import { Tabs, Tab, AppBar, Box, Paper, Checkbox, FormGroup, FormControlLabel, Grid, TextField, Select, MenuItem, Button, InputLabel, FormControl, Tooltip, Typography, Switch, Slider, Radio, RadioGroup, CircularProgress, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import { Link } from "react-router-dom";
import Banner from './Banner';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import ClientTable from './ClientTable';
import EditUser from './EditUser';
import AgentMatch from './AgentMatch';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const Dashboard = () => {  
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>     
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered        
        >
          {isPro && <Tab label="Clients" {...a11yProps(0)} /> }
          {isPro && <Tab label="History" {...a11yProps(1)} /> }
          {!isPro && <Tab label="My Match" {...a11yProps(0)} /> }
          <Tab label="Support" {...a11yProps(3)} />
          <Tab label="My Information" {...a11yProps(4)} />
        </Tabs>        
      </AppBar> 
      {isPro &&
        <TabPanel value={value} index={0}>        
          <Grid container spacing={2} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <Typography>Click on a client to manage the match. Details of new matches are hidden until accepted. </Typography>            
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <ClientTable completed={false}/>            
            </Grid>
          </Grid>
        </TabPanel>
      }
      {isPro &&
        <TabPanel value={value} index={1}>       
          <Grid container spacing={2} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <Typography>Click on a client to view the transaction.</Typography>            
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <ClientTable completed={true} />            
            </Grid>
          </Grid>
        </TabPanel>
      }
      {!isPro &&
        <TabPanel value={value} index={0}>       
          <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '400px' }}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <AgentMatch />
            </Grid>
          </Grid>
        </TabPanel>
      }
      <TabPanel value={value} index={isPro ? 2 : 1}>   
        <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '400px' }}>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
            For any questions or concerns, please email us at&nbsp;<a style={{textDecoration: 'none', color: '#1B75BB'}} href="mailto:support@MyBrokerMatch.com">support@MyBrokerMatch.com</a>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={isPro ? 3 : 2}>   
        <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
            <EditUser />
          </Grid> 
        </Grid>
      </TabPanel>
      <div style={{height: '250px'}} />
    </React.Fragment>
  )

};

export default Dashboard;