import { Typography } from '@material-ui/core';
import React from 'react';
import hero from './images/default_hero-img.jpg';

const Banner = ({titleText}) => {

  return (
    <div  style={{ backgroundImage: `url(${hero})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '263px', marginTop: '-8px' }}>
      <Typography style={{color: 'white', fontSize: '50px', fontWeight: 'bold',textShadow: '1px 1px 2px rgb(0 0 0 / 50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>{titleText}</Typography>
    </div>      
  )
}

export default Banner;