import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core/styles';
import MBMTheme from './MBMTheme';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.send("pageview");

const options = {
  autoConfig: true, 
  debug: false, 
};
ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, null, options);
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="auth.mybrokermatch.com"
      clientId={process.env.REACT_APP_CLIENT_ID}
      redirectUri={window.location.origin}    
    >
      <ThemeProvider theme={MBMTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);