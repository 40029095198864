import { Checkbox, FormControlLabel, Grid, TextField, Select, MenuItem, Button, IconButton, InputLabel, FormControl, Tooltip, Typography, Switch, Slider, Radio, RadioGroup, CircularProgress, InputAdornment, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import brokerImage from "./images/broker_couple_1.jpg";

const formatPhoneNumber = (input) => {
    input = ('' + input).replace(/\D/g,'');
          
    input = input.substring(0,10);
  
    var size = input.length;
    if(size === 0){
            input = input;
    }else if(size < 4){
            input = '('+input;
    }else if(size < 7){
            input = '('+input.substring(0,3)+') '+input.substring(3,6);
    }else{
            input = '('+input.substring(0,3)+') '+input.substring(3,6)+' - '+input.substring(6,10);
    }
    return input; 
  }


  const validationSchema = yup.object({
    firstName: yup
      .string('Enter your first name')
      .required('First Name is required'),
    lastName: yup
      .string('Enter your last name')
      .required('Last Name is required'),
    email: yup
      .string('Enter your Email Address')
      .required('Email Address is required'),
  
  });

  const getWidth = () => {
    var kk = window.innerWidth;

    if (kk > 900)
    {
      return 900;
    }

    if (kk < 400)
    {
      return 400;
    }

    return kk;
  };

const ClientLead = () => {    
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [width, setWidth] = React.useState(getWidth());

  useEffect(() => {
      window.scrollTo({top: 0, behavior:"smooth"});
  }, []);
  
  
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      comment: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {    
      setSubmitting(true);  
      let url = process.env.REACT_APP_BASE_URL + '/api/v2/Leads?code='+ process.env.REACT_APP_API_KEY;
      console.log(values);
 
      axios({
        method: 'post',
        url: url,
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then((response) => 
      {
        setSubmitted(true);
      })
      .catch((error) =>
      {
        console.log(error);
        setSubmitting(false);
      });
    },
  });  

  return (
      <React.Fragment>
      <div style={{ marginTop: '32px', marginBottom: '32px'}}>
          <Grid container spacing={2} style={{ width: width, marginLeft: 'auto', marginRight: 'auto' }} >
              <Grid item xs={12} style={{ fontSize: '32px', display: 'flex', justifyContent: 'center', textAlign: 'center', padding: 0 }}>
                <strong>Match with a Broker Today!</strong>
              </Grid>
              <Hidden smDown>
                <Grid item md={5}>
                  <img src={brokerImage} alt="Broker" width="343" style={{paddingTop: '24px'}} />
                </Grid>
              </Hidden>
              <Grid item xs={12} md={7} style={{ fontSize: '16px' }}>
                  <p><strong>Geography and the agent’s financial success are not valid predictors of a positive real estate experience for the buyer or seller.</strong></p>
                  <p><strong>We employ a unique process that matches home buyers and sellers with the best REALTOR® for their transaction.</strong></p>
                  <p><strong>Our software compares points of data to predict compatibility for a positive buying or selling experience.</strong></p>
                  <p><strong>MyBrokerMatch.com collects information about your needs, opinions, and personality using a simple and easy-to-use questionnaire to compile the data necessary to make a successful match.</strong></p>        
              </Grid>
          </Grid>
      </div>
      <div style={{ marginTop: '32px', marginBottom: '32px'}}>
        {!submitted &&
        <form onSubmit={formik.handleSubmit} noValidate="noValidate">
          <Grid container spacing={2} style={{ width: width, marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}   
              />
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}   
              />
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                style={{width: '90%'}}
                value={formik.values.phone}
                onChange={(e, value) => { formik.setFieldValue('phone', formatPhoneNumber(e.target.value));}}
                error={formik.touched.phone && Boolean(formik.errors.phone)}   
              />
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="email"
                name="email"
                label="Email Address"
                variant="outlined"
                required
                style={{width: '90%'}}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <TextField
                id="comment"
                name="comment"
                label="Tell us what's important to you"
                variant="outlined"
                multiline
                rows={2}
                style={{width: '90%'}}
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.touched.comment && Boolean(formik.errors.comment)}   
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Button variant='contained' color='primary' type="submit" disabled={submitting}>
                Submit to Start the Matching Process
              </Button>
            </Grid>
          </Grid>
        </form>
        }
        {submitted &&
          <Grid container spacing={2} style={{ width: width, marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid item xs={12} style={{ fontSize: '16px', display: 'flex', justifyContent: 'center' }}>
                  <p><strong>Thank you, one of our representatives will reach out to you soon.</strong></p>
              </Grid>
          </Grid>
        }
      </div>

      </React.Fragment>
  );

}

export default ClientLead;