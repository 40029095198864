import React from "react";
import Typography from "@material-ui/core/Typography";

const DisclosureText = () => {
  return (
    <Typography>
                            <p>Notice to any User of the MyBrokerMatch.com website: Your use of the MyBrokerMatch.com web site signifies your agreement to the MyBrokerMatch.com web site Disclosure/Privacy Policy as detailed below. Please read the entire below agreement before using the MyBrokerMatch.com web site.</p>
<p><strong>How Your Personal Information Is Treated and Used</strong></p>
<p>At MyBrokerMatch.com, we are committed to protecting your privacy and creating a safe and secure online environment for anyone who visits and uses our site. All information is confidential and is never sold, rented, or otherwise used for any purpose other than to help us best match homebuyers and REALTORS®. We use algorithms to reliably predict professional unions that are likely to result in a successful home purchase. The match should be satisfactory to both parties, whether you are matching in a home purchase arrangement, or opting in to being contacted by one of our preferred providers of mortgages or insurance. By visiting MyBrokerMatch.com, you are accepting the practices and terms described in this Privacy Notice.</p>
<p>When you submit information to MyBrokerMatch.com to create a suggested homebuyer-REALTOR® match, you authorize MyBrokerMatch.com to utilize that information in any way required of MyBrokerMatch.com’s computerized matching programs necessary to place a specific homebuyer(s)/ seller(s) with a particular REALTOR® as suggested by the provided profile information of both parties. In some areas of the US, real estate professionals have opted not to be members of the local, state, or national REALTOR® associations. This has nothing to do with required licensing, and in these areas, homebuyers/sellers will be matched with a qualified real estate professional who is not a REALTOR®.</p>
<p>Homebuyers and REALTORS® acknowledge that not all computerized matches may result in immediately acceptable professional unions once the parties establish personal contact. &nbsp;In that event, should the homebuyer(s) decide not to accept the highest scoring matched REALTOR® &nbsp;suggested by the information provided by both parties, the homebuyer(s) shall use &nbsp;MyBrokerMatch.com to give notice that the recommended REALTOR® has been declined and MyBrokerMatch.com shall apply the same criteria and methodology to provide an alternative match.</p>
<p>Any MyBrokerMatch.com user acknowledges and accepts that despite all MyBrokerMatch.com efforts to merge complex mathematical formulas, professionally created personality questionnaires and sophisticated computer software programs into a reliable system of predicting compatible and successful homebuyer-REALTOR® matches, the vagaries of human interactions and the accuracy of provided information combined with the unpredictable nature of human events plus the national economy make it possible that a specific homebuyer and/or REALTOR® may not enjoy a successful home purchase/sale. Therefore, the users of MyBrokerMatch.com acknowledge the possibility of unsuccessful matches (or no matches at all) by using MyBrokerMatch.com and thereby waive, in advance, any future claims of any liability against MyBrokerMatch.com for any homebuyer-REALTOR® matching that does not result in a desired relationship, home purchase or any other expectation of any user of MyBrokerMatch.com.</p>
<p>Any homebuyer or REALTOR® who utilizes the services of MyBrokerMatch.com expressly consents to being contacted by MyBrokerMatch.com and by the appropriate homebuyer(s) or REALTOR® via phone, email, mail or other reasonable means as approved on the said party’s application as completed on the MyBrokerMatch.com web site, at any of the provided contact numbers or addresses for reasonable purposes related to the services provided by MyBrokerMatch.com.</p>
<p><strong>Links to Third Parties</strong></p>
<p>The MyBrokerMatch.com web site may contain links to web sites maintained by third parties. It is possible that hyperlinks to third parties may be part of the MyBrokerMatch.com web site. Please be aware that these links are provided for your convenience and for reference purposes, only. MyBrokerMatch.com does not operate, control, endorse or manage any information, software, product or services available on such web sites. MyBrokerMatch.com does not represent or warrant that any such third party is licensed, qualified, reputable or capable of performing any such offered service(s). Any user of the MyBrokerMatch.com web site accepts, in advance, that MyBrokerMatch.com is not responsible or liable for any loss or damage of any sort incurred as the result of any user’s purchase, investigation, or otherwise utilization of any third-party products or services. Said third-party products and services are purchased, investigated or otherwise utilized at the user’s own risk. Accordingly, MyBrokerMatch.com does not sponsor, endorse, recommend or approve any such third party.</p>
<p><strong>Legal Disclaimer</strong></p>
<p>MyBrokerMatch.com reserves the right to disclose any user’s personal information as required by law and when MyBrokerMatch.com believes that disclosure is necessary to protect MyBrokerMatch.com’s rights and/or to comply with a judicial proceeding, court order, or legal process served on MyBrokerMatch.com.</p>
<p><strong>Security</strong></p>
<p>MyBrokerMatch.com follows generally accepted industry standards to protect the personal information submitted to, and sent from, MyBrokerMatch.com. Despite all security measures taken by MyBrokerMatch.com to protect client information from hacking or other breaches of security, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while MyBrokerMatch.com strives to use commercially acceptable means to protect any user’s personal information, MyBrokerMatch.com does not guarantee its absolute security. Any MyBrokerMatch.com user acknowledges this security limitation to waive, in advance, any claims of any nature against MyBrokerMatch.com for any loss of client information as the result of hacking or other breaches of security.</p>
<p>Each person that is provided with a password and user identification to use the MyBrokerMatch.com web site, if applicable, must agree to abide by these MyBrokerMatch.com web site terms and is responsible for all activity under such user identification. Each user is responsible for maintaining the confidentiality and security of any password connected with his/her/their account.</p>
<p>If users have any questions about security on the MyBrokerMatch.com web site, a user can send an E- mail question to the company at support@MyBrokerMatch.com .</p>
<p><strong>Broker/Agent Participation Policy</strong></p>
<p>By using the services provided by MyBrokerMatch.com you, as a Broker/Agent, agree to the Broker/Agent Participation Policy. You understand that any match initiated by the MyBrokerMatch service that leads to a successful real estate transaction is subject to a referral fee. Unless determined otherwise by a promotion or an agreement that supercedes this one, the fee will be 35% of base commission. You understand that by accepting a match while using the services of MyBrokerMatch, you agree to pay this referral fee. You understand that your subscription status, does not have bearing on your obligation to pay this referral fee. You understand that MyBrokerMatch LLC may contact the closing agent and/or client regarding this obligation. </p>
<p><strong>Broker/Agent Refund Policy</strong></p>
<p>Brokers/Agents may cancel their subscription to MyBrokerMatch at any time simply by sending us an email to support@mybrokermatch.com. If you prepaid for a year, you will be refunded any unused portion of your subscription based on a pro-rated amount of $8.32 per month. Refunds will be processed within 10 business days from receipt of cancellation. No partial months will be refunded. If you are on a monthly subscription, then you will not be billed for any of the months following the month of your last payment.</p>
<p>Please note that cancelling your subscription does not relieve you of any referral fees due MyBrokerMatch for any business generated as the result of our matching you with a client.</p>
<p><strong>Customer Refund Policy</strong></p>
<p>If, for any reason, you are unhappy with your subscription to MyBrokerMatch, please send an email to support@mybrokermatch.com and we will refund your membership fee. Your refund will be processed within 10 business days from receipt of cancellation.</p>
<p>If you are matched with a real estate professional (broker) and continue to work with him/her on a transaction, please note that cancelling your subscription does not relieve the real estate professional of other obligations they may have with MyBrokerMatch.</p>
<p><strong>User Comments / Testimonials / Postings</strong></p>
<p>If a “Public Area” is available on the MyBrokerMatch.com web site, such as a forum, message board, or other community area that allows user posts, the user is solely responsible for any information, comments, text, photos, content or material that the user uploads, posts or transmits to the MyBrokerMatch.com web site (“User Content”).</p>
<p>Users of the MyBrokerMatch.com web site agree not to engage in any of the following activities, any of which would be deemed a breach of the MyBrokerMatch.com web site terms:</p>
<ul>
<li>Violating local, state, or other applicable laws or regulations;</li>
<li>Posting content that infringes the intellectual property rights, privacy, publicity or other rights of any third party;</li>
<li>Posting content that is unlawful, obscene, defamatory, threatening, harassing, hateful, or embarrassing to any third party as determined in MyBrokerMatch.com’s sole discretion;</li>
<li>Posting advertisements or other materials to solicit business for yourself or any third party;</li>
<li>Disrupting or impeding the discussion flow of others on the MyBrokerMatch.com web site;</li>
<li>Posting chain letters or pyramid schemes;</li>
<li>Impersonating a third party or implying you are someone other than who you are;</li>
<li>Distributing viruses or other harmful or malicious computer code;</li>
<li>Collecting information about others without their explicit written consent;</li>
<li>Allowing a third party to use your identification or to pretend they are you; or</li>
<li>Engaging in any conduct that disrupts or impedes a third party’s use and enjoyment of the MyBrokerMatch.com web site, or which, in MyBrokerMatch.com’s sole judgment, exposes MyBrokerMatch.com or any customers, partners or related entities to liability or detriment of any type</li>
</ul>
<p>MyBrokerMatch.com may, but is not obligated to, do one or more of the following:</p>
<ol>
<li>Record, monitor, modify or disclose any User Content (“user content” being defined in this Disclosure / Privacy Policy statement as any information, comments, questions and/or postings of any nature) with said disclosures of User Content being disclosures required by law or to protect MyBrokerMatch.com’s legal interests;</li>
<li>Investigate a complaint that any user’s content violates the MyBrokerMatch.com web site terms, and determine in MyBrokerMatch.com’s sole discretion whether to remove the material or take other action;</li>
<li>Remove User Content if we determine in our sole discretion that a violation of MyBrokerMatch.com’s web site terms has occurred or may occur in the future; or</li>
<li>Terminate a user’s access to the MyBrokerMatch.com web site (or any portion thereof) if the user has violated the MyBrokerMatch.com web site terms.</li>
</ol>
<p><strong>Submissions</strong></p>
<p>Any user of the MyBrokerMatch.com web site agrees that any materials, including but not limited to questions, comments, suggestions, ideas, plans, notes, drawings, original or creative materials or other information regarding MyBrokerMatch.com or the MyBrokerMatch.com web site, provided by said user in the form of E-mail or submissions to MyBrokerMatch.com, or postings on the MyBrokerMatch.com web site, are non-confidential. MyBrokerMatch.com will own exclusive rights, including all intellectual property rights, and will be entitled to the unrestricted use of these materials for any purpose, commercial or otherwise, without acknowledgment or compensation to any said user.</p>
<p><strong>Profile</strong><br/>The company stores information collected through cookies, or log files to create a “profile” of user preferences. The company ties user’s personal information to information in their profile, in order to provide tailored promotions and marketing offers and improve the content of the site for user.</p>
<p><strong>Cookies</strong><br/>A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We use cookies on this site. We use both session ID cookies and persistent cookies. We use session cookies to make it easier for you to navigate our site. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser’s “help” file.</p>
<p>By setting a persistent cookie, we enable our users to not have to log in with a password more than once, thereby saving time while on our web site. If you reject cookies, you may still use our site, but your ability to use some areas of our site, such as contests or surveys, will be limited.</p>
<p>Some of our business partners (e.g., advertisers) use cookies on our site. We have no access to or control over these cookies.</p>
<p>This privacy policy covers the use of cookies by MyBrokerMatch.com only and does not cover the use of cookies by any advertisers.</p>
<p>We do not link the information we store in cookies to any personal information you submit while on our site.</p>
<p><strong>Access to Personal Information</strong></p>
<p>If your personal information changes, or if you no longer desire our service, you may correct, update, delete, or deactivate it by making the change on our user information page or by emailing our Customer Support at support@MyBrokerMatch.com or by contacting us by telephone or postal mail at the contact information listed below.</p>
<p>We will retain your information for as long as your account is active, as needed to provide you services or as required by law. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
<p><strong>Opt-Out/Choice</strong></p>
<p>If you give the company your information over this website, and you wish to opt-out of receiving communications from us, our partners, or third parties, please send us an email to support@MyBrokerMatch.com; or, you can write to: Attn:Customer Support, MyBrokerMatch.com; 3061 Trenwest Drive; Winston- Salem, NC 27103.</p>
<p><strong>Use of site; limited license</strong></p>
<p>You are granted a limited, non-sublicensable license to access and use the Site and all content, data, information and materials included in the MyBrokerMatch.com web site solely for your personal use, subject to the terms and conditions set forth in these MyBrokerMatch.com web site terms.<br/>You agree that you will not access MyBrokerMatch.com’s web site for any illegal or malicious purpose. You agree to access only those portions of the MyBrokerMatch.com web site to which MyBrokerMatch.com has granted you access and you will not attempt to access any password secured zone without permission of MyBrokerMatch.com which reserves the right to limit your use of MyBrokerMatch.com’s web site, or to block your future use from MyBrokerMatch.com’s web site, or any part thereof, at MyBrokerMatch.com’s sole discretion.</p>
<p>You may not use the MyBrokerMatch.com web site or any MyBrokerMatch.com web site materials for commercial purposes. You agree that you will not modify, copy, distribute, resell, transmit, display, perform, reproduce, publish, license, create derivative works from, frame in another web page, use on any other web site or service any of the MyBrokerMatch.com web site materials. You will not use the MyBrokerMatch.com web site or any of the MyBrokerMatch.com web site materials other than for their intended purpose or in any way that is unlawful, or harms MyBrokerMatch.com and/or its suppliers.</p>
<p>Without prejudice to the foregoing, you may not engage in the practices of “screen scraping,” “database scraping,” “data mining” or any other activity with the purpose of obtaining lists of users or other information from the MyBrokerMatch.com web site or that uses web ”bots” or similar data gathering or extraction methods. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the MyBrokerMatch.com web site.</p>
<p>Any use of the MyBrokerMatch.com web site or the MyBrokerMatch.com web site materials other than as specifically authorized herein is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these MyBrokerMatch.com web site terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable by us at any time.</p>
<p><strong>Disclaimer</strong></p>
<p>THE SITE AND THE SITE MATERIALS (INCLUDING ALL THIRD PARTY CONTENT), AND ALL LINKS, INFORMATION, MATERIALS, EVALUATIONS, RECOMMENDATIONS, SERVICES AND PRODUCTS PROVIDED ON OR THROUGH THE SITE ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU EXPRESSLY AGREE THAT USE OF THE SITE AND THE MATERIALS IS AT YOUR SOLE RISK. MyBrokerMatch.com AND ITS SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON- INFRINGEMENT AS TO THE SITE, THE SITE MATERIALS, LINKS, INFORMATION, MATERIALS, SERVICES AND PRODUCTS AVAILABLE ON OR THROUGH THE SITE.</p>
<p><strong>Limitation of liability</strong></p>
<p>IN NO EVENT WILL MyBrokerMatch.com OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, THE USE OF THE MyBrokerMatch.com WEB SITE OR THE MyBrokerMatch.com WEB SITE MATERIALS.</p>
<p><strong>Termination</strong></p>
<p>MyBrokerMatch.com may terminate or suspend any user’s access to the MyBrokerMatch.com web site at any time, with or without cause (but generally for a user’s violation of the web site’s terms of use), and with or without notice. Upon such termination or suspension, the terminated user’s right to use the MyBrokerMatch.com web site will immediately cease. Furthermore, MyBrokerMatch.com reserves the right not to respond to any requests for information for any reason, or no reason, unless legally compelled to do so.</p>
<p><strong>Applicable Law and Venue</strong></p>
<p>Any claim or controversy arising out of or related in any way to these terms or to your access of the MyBrokerMatch.com web site shall be settled by arbitration in Winston-Salem, North Carolina under the then-prevailing rules of the American Arbitration Association. Any such arbitration must be commenced no later than one (1) year from the date such claim or controversy arose. The award of the arbitrator shall be final and binding and judgment may be entered in any court of competent jurisdiction. The arbitrator’s fee shall be shared equally by the parties. The prevailing party in the arbitration proceeding shall be awarded reasonable attorney fees, expert witness costs and expenses, and all other costs and expenses incurred in connection with the proceedings, unless the arbitrator shall for good cause determine otherwise. No individual arbitration proceeding may be joined with another arbitration proceeding. Nothing in this Section prevents you or MyBrokerMatch.com from seeking court-ordered injunctive relief provided said court proceedings are conducted only in a state or federal court located in Winston-Salem, North Carolina.</p>
<p><strong>General Terms</strong></p>
<p>If any part of the MyBrokerMatch.com web site terms is determined to be invalid or unenforceable pursuant to applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the MyBrokerMatch.com web site terms will continue in effect. MyBrokerMatch.com may assign this Agreement, in whole or in part, at any time with or without notice to any user of the MyBrokerMatch.com web site. It is expressly agreed that no user of the MyBrokerMatch.com web site may assign this Agreement, or assign, transfer or sublicense any user’s rights, if any, in the MyBrokerMatch.com web site. Except as expressly stated herein, the MyBrokerMatch.com web site terms constitute the entire agreement between the user and MyBrokerMatch.com with respect to the MyBrokerMatch.com web site.</p>
<p><strong>Copyright, Trademark and Patent Notices</strong></p>
<p>Portions of the Site are protected by MyBrokerMatch.com intellectual property rights and unless otherwise explicitly indicated in the MyBrokerMatch.com web site, the MyBrokerMatch.com web site materials and the selection and arrangement thereof are the proprietary property of MyBrokerMatch.com plus its suppliers and are protected by U.S. and international copyright and servicemark laws.</p>
<p>The MyBrokerMatch.com web site name, and any MyBrokerMatch.com products, services, slogans or logos referenced herein are either trademarks or registered trademarks of MyBrokerMatch.com in the United States and/or other countries. The names of actual third party companies and products mentioned in the Site may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved.</p>
<p><strong>Contact Company</strong></p>
<p>If users have any questions or suggestions regarding the company privacy policy, please contact company at:</p>
<p>MyBrokerMatch.com<br/>3061 Trenwest Drive<br/>Winston-Salem, NC 27103.<br/>support@MyBrokerMatch.com</p>
<p><strong>Privacy Policy Updates</strong></p>
<p>MyBrokerMatch.com may, in its sole discretion, revise this Privacy Policy at any time. All such revisions shall be effective when posted. If MyBrokerMatch.com changes the way any user’s personal information will be used, MyBrokerMatch.com will post an announcement on its homepage. If MyBrokerMatch.com makes any material changes, MyBrokerMatch.com will notify users by E-mail (sent to the E-mail address specified in the user’s account) or by means of a notice on this MyBrokerMatch.com web site prior to the change becoming effective. MyBrokerMatch.com encourages all users to make periodic reviews of this Privacy Policy to stay apprised of MyBrokerMatch.com’s current privacy practices. If any user does not agree to the amended terms, that user agrees to stop using the MyBrokerMatch.com web site.</p>
<p>INDEMNIFICATION</p>
<p>YOU AGREE TO INDEMNIFY AND HOLD HARMLESS MyBrokerMatch.com, ITS AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES FROM ANY AND ALL CLAIMS/ CAUSES OF ACTIONS BROUGHT AGAINST THEM BY ANY PARTY ARISING OUT OF CONTENT SUBMITTED, UPLOADED OR OTHERWISE PROVIDED BY YOU TO MyBrokerMatch.com AND FOR ANY CLAIM OR CAUSE OF ACTION BROUGHT ON THE BASIS OF YOUR BREACH OF THESE TERMS OR MyBrokerMatch.com’s PRIVACY POLICY.</p>
<p>MyBrokerMatch, LLC is a North Carolina limited liability company.</p>

    </Typography>

  )
}

export default DisclosureText;