import React from "react";
import Typography from "@material-ui/core/Typography";
import Banner from "./Banner";
import { Grid } from "@material-ui/core";

const Contact = () => {

    return (
      <React.Fragment>
        <div style={{ marginTop: '-51px' }} />
        <Banner titleText="Contact Us" />
        <Grid container spacing={4} style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px' }}>
          <Grid item xs={12} spacing={4}>
            <Typography style={{ fontSize: '18px', textDecoration: 'none', color: '#3b3b3b'}}>
              If you have questions or require support, please email us at <a style={{textDecoration: 'none', color: '#1B75BB'}} href="mailto:info@MyBrokerMatch.com">info@MyBrokerMatch.com</a><br />
              <br />
              Address:<br/>
              3061 Trenwest Drive<br/>
              Winston-Salem, NC 27103<br/><br/>
              Phone: (336) 893-9928<br/><br/><br/>

            </Typography>
          </Grid>
        </Grid>

      </React.Fragment>
    );
};

export default Contact;