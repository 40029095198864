import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import faqbg from "./images/bg_faqs_1.jpg";
import Accordion from "@material-ui/core/Accordion";
import { AccordionSummary, Container, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Banner from './Banner';

const ClientFAQ = () => {

    useEffect(() => {
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [expanded, setExpanded] = React.useState(false);  

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

  return (
      <React.Fragment>
        <div style={{ marginTop: '-51px' }} />
        <Banner titleText="Client FAQs" />
        <div id="faq" style={{ backgroundImage: `url(${faqbg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', marginTop: "16px", paddingTop: "16px", paddingBottom: "16px", backgroundAttachment: "fixed"}}>
            <Container width='900px'>                 
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        Why should I use a matching service to help me find a REALTOR®?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Purchasing a home is the largest, most complex legal transaction that most people ever undertake. People often move to a location where they don’t know anyone, so having the right REALTOR® is imperative. A matching service can help connect you to the perfect real estate professional - one that is licensed and experienced in your desired location.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                   
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        Why use MyBrokerMatch.com?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Other services make this match simply by assigning potential clients to one of the top-selling agents in their desired area. Usually, the “top agent” is too busy to provide the attention a new client requires. Even if a client is assigned to one of the “top agent’s” team members – that REALTOR® may not have the same expertise and experience. With just any agent willing to take on a new client, there is no guarantee that their personalities will mesh. Since every transaction is complex and important, MyBrokerMatch.com matches the consumer with a REALTOR® who has an extremely high probability of compatibility with the client, while facilitating the transaction competently.<br/>
                        In addition to each participating REALTOR® adhering to the REALTOR® Code of Ethics, he/she also agrees to abide by the MyBrokerMatch.Com Letter of Expectation.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        How does MyBrokerMatch.Com match consumers and REALTORS®?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        The REALTOR® and consumer take similar questionnaires from which the system collects pertinent data. Then, a proprietary algorithm matches the consumers to the REALTORS® with whom they are most likely to enjoy a successful and enjoyable transaction.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        What are some of the criteria for a successful match?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Although this is proprietary information, we can tell you this much…the system is the result of many, many years of accumulated real estate experience and then working closely with a psychologist to create the formula.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        It only costs me $24.95 upfront; are there any other fees involved?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        There are no additional or hidden charges for the consumer. The REALTOR® also pays a nominal subscription fee, as well as a success fee to MyBrokerMatch.com.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        What if there is no REALTOR® that is a match for me?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        In extremely rare instances, there may not be a REALTOR® in your desired area that matches you. If this is the case, you will be notified and receive a full refund.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        Do I have a say about my match?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        We are confident in the success of our system. However, in the very rare case that you are not satisfied with your match, you may ask for a second match with another REALTOR®. Should that match not be to your satisfaction, you may withdraw from the system and receive a full refund.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        Is this a long-term commitment?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Use of the program is voluntary and designed primarily for a single or dual transaction. You may withdraw at any time. However, since you and the REALTOR® have both put effort into the process, we ask that you respect the match. Circumventing the system by an unreported transaction with the matching agent could result in serious repercussions for the REALTOR®.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        If I use the system to sell in one city and buy in another, will I pay two fees?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Selling in one city and buying in another will probably result in matching with two separate REALTORS®; however, you will only pay one fee.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        If I stop in the middle of the questionnaire, can I come back later and finish it?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        It only takes about 20 minutes to complete the questionnaire. However, if you are interrupted, you may simply log back in and pick up where you left off. We want you to take your time and put as much thought into it as possible.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                           
                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none', borderBottomStyle: 'solid', borderBottomColor: 'white', borderBottomWidth: '2px'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        If two people are buying together; should they fill out the questionnaire together?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        No. The person who will be most heavily involved in working with the REALTOR® should be the one to fill out the questionnaire. This person will be interacting most with the REALTOR® and they need to be compatible in order to achieve the best outcome.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                                         
                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} style={{ marginLeft: '20%', marginRight: '20%', backgroundColor: 'transparent', boxShadow: 'none'}}>
                    <AccordionSummary  
                    expandIcon={<ExpandMoreIcon style={{color: 'white'}} />} >
                        <Typography style={{color: 'white'}}>
                        I have friends/family following me into the same area. Should I just have them contact my matched REALTOR®?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                        <Typography style={{ color: 'white'}}>
                        Since everyone’s personality is different; most of the matches will be different. We would recommend you have them benefit from the system as you have.
                        </Typography>
                    </AccordionDetails>
                </Accordion>                      
            </Container>
        </div>
      </React.Fragment>
  );
};

export default ClientFAQ;