import { createMuiTheme } from '@material-ui/core/styles';

const DemoTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#1B75BB'
        },
        secondary: {
            main: '#8BC53F'
        },
        important: {
            main: '#F15A29'
        }
    },
    typography: {
        fontFamily: 'Gotham'
    }
});

export default DemoTheme;