import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography, IconButton, TextField, Grid, Backdrop, CircularProgress } from "@material-ui/core";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Tooltip from "@material-ui/core/Tooltip";
import Home from "./Home";
import Footer from "./Footer";
import Disclosure from "./Disclosure";
import Contact from "./Contact";
import header1 from "./images/background_hero_all.jpg";
import logo1 from "./images/logo.svg";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AgentFAQ from "./AgentFAQ";
import popupbg from "./images/background_popup.jpg";
import UserDetails from "./UserDetails";
import Questionnaire from "./Questionnaire";
import Dashboard from "./Dashboard";
import axios from 'axios';
import ClientLead from "./ClientLead";
import AgentLead from "./AgentLead";
import AboutUs from "./AboutUs";
import ClientFAQ from "./ClientFAQ";


function App() {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const history = useHistory();
  const [showLoading, setShowLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [isPro, setIsPro] = React.useState(false);
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmail] = React.useState();
  const [firm, setFirm] = React.useState();
  const [apiUser, setApiUser] = React.useState();
  const [init, setInit] = React.useState(false);

  useEffect(() => {
      updateApiUser();
  }, [user]);

  useEffect(() => {
    if (user && !apiUser)
    {
      updateApiUser();
    }

    if (apiUser && apiUser.hasSubscribed && apiUser.hasFinishedQuestionnaire && !init)
    {
      setInit(true);
      history.push('/dashboard');
    }
    else if (apiUser && apiUser.hasSubscribed && !apiUser.hasFinishedQuestionnaire && !init)
    {
      setInit(true);
      history.push('/assessment');
    }
    else if (apiUser && !apiUser.hasSubscribed && window.location.pathname != "/assessment" && !init)
    {
      setInit(true);
      history.push('/createaccount');
    }
  }, [apiUser]);

  const updateApiUser = () => {
    if (isAuthenticated && user && user.email)
    {
      setShowLoading(true);
      axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Users/'+ user.email +'?code='+ process.env.REACT_APP_API_KEY + '')
      .then((response) => {
        setApiUser(response.data);
        setIsPro(response.data.isPro);
        setShowLoading(false);
      })
      .catch((response) => 
      {
        console.log("User Not Found", response);
        setShowLoading(false);
        history.push('/createaccount');
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.scrollTo(0,0);
  };
  const handleSubmit = () => {
    setOpen(false);
    setIsPro(true);
    document.cookie = 'firstName=' + encodeURIComponent(firstName) + '; max-age=28800;';
    document.cookie = 'lastName=' + encodeURIComponent(lastName) + '; max-age=28800;';
    document.cookie = 'phone=' + encodeURIComponent(phone) + '; max-age=28800;';
    document.cookie = 'email=' + encodeURIComponent(email) + '; max-age=28800;';
    document.cookie = 'firm=' + encodeURIComponent(firm) + '; max-age=28800;';
    window.scrollTo({
      top: 0,
      behavior: "smooth"});
  }

  return (
    <React.Fragment>
      <AppContext.Provider value={{ user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading }}>
        {window.matchMedia("(max-width: 768px)").matches ?
        <React.Fragment>
          <div id="phoneTop" style={{ display: "none", position: "absolute", top: "0", right: "0", color: "#8BC53F", zIndex: "1000", padding: "12px", fontSize: "19px"}} >336-893-9928</div>
        </React.Fragment> :
        window.matchMedia("(max-width: 1024px)").matches ?
        <React.Fragment>
          <div id="phoneTop" style={{ position: "absolute", top: "0", right: "0", color: "#8BC53F", zIndex: "1000", padding: "12px", fontSize: "19px"}} >336-893-9928</div>
        </React.Fragment> :
        <React.Fragment>
          <div id="phoneTop" style={{ position: "absolute", top: "0", right: "0", color: "#8BC53F", zIndex: "1000", padding: "24px", fontSize: "27px"}} >336-893-9928</div>
        </React.Fragment>}        
        <div id="home" />
        {isAuthenticated ? 
        <React.Fragment>
          <AppBar position="fixed">
            <Toolbar>
              <React.Fragment>
                <Link style={{ textDecoration: 'none', color: 'white' }} to="/">
                  <Typography variant="h5" noWrap>
                    MyBrokerMatch.com
                  </Typography>
                </Link>
                <div style={{ flexGrow: 1}}></div>
                <Button onClick={() => logout({ returnTo: window.location.protocol + '//' + window.location.host })} variant="contained" color="secondary" style={{ marginRight: '8px' }}>
                  <Typography style={{ color: 'white'}}>
                    Log out
                  </Typography>
                </Button>
                <Tooltip title={user.name ? user.name : user.email}>
                  <IconButton>
                    <AccountCircle fontSize="large" style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </Toolbar>
          </AppBar>
          <Toolbar /> 
        </React.Fragment> :
        <React.Fragment>        
          <div style={{ position: 'relative' }}>
                  <Button style={{ position: 'absolute', top: '14%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white'}}
                    onClick={() => loginWithRedirect()}
                  >
                      Login or Sign Up
                  </Button>
                  <Link to="/">
                  <img src={logo1} style={{ position: 'absolute', top: '64%', left: '50%', transform: 'translate(-50%, -50%)', width: '29%' }} />
                  </Link>
                  <img src={header1} style={{ width: '100%' }} />

          </div>
        </React.Fragment>}
        <React.Fragment>
          <div justify="center" style={{ position: 'relative', alignContent: 'center', marginTop: '8px' }}>
            <Grid container justify="center" spacing={1} style={{ alignContent: 'center', textAlign: 'center'}}>
              <Grid item xs={12} md={2} >
                <Link to="/aboutus">
                  <Button variant="contained" color="secondary" style={{ width: '90%'}} >
                    <Typography style={{ color: 'white' }} >
                      About Us
                    </Typography>
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={2}>
                <Link to="/clients/faqs">
                  <Button variant="contained" color="secondary"  style={{ width: '90%'}} onClick={() => console.log()} >
                    <Typography style={{ color: 'white' }} >
                      FAQS
                    </Typography>
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={2} >
                <Link to="/clients/start">
                  <Button variant="contained" color="secondary"  style={{ width: '90%'}} >
                    <Typography style={{ color: 'white' }} >
                      Sign Up
                    </Typography>
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={2} >
                <Link to="/agents/start">
                  <Button variant="contained" color="secondary"  style={{ width: '90%'}}>
                    <Typography style={{ color: 'white' }} >
                      Realtors
                    </Typography>
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={2} >
                <Link to="/contact">
                  <Button variant="contained" color="secondary"  style={{ width: '90%'}}>
                    <Typography style={{ color: 'white' }} >
                      Contact Us
                    </Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/disclosure">
            <Disclosure />
          </Route>
          <Route path="/clients/start">
            <ClientLead />
          </Route>
          <Route path="/agents/start">
            <AgentLead />
          </Route>
          <Route path="/aboutus">
            <AboutUs />
          </Route>
          <Route path="/clients/faqs">
            <ClientFAQ />
          </Route>
          <Route path="/agents/faqs">
            <AgentFAQ />
          </Route>
              
          {isAuthenticated && 
            <Route path="/createaccount">
              <UserDetails />
            </Route>
          }
          {isAuthenticated && apiUser &&
            <Route path="/assessment">
              <Questionnaire />
            </Route>
          }
          {isAuthenticated && apiUser &&
            <Route path="/dashboard">
              <Dashboard />
            </Route>
          }

          <Route path="/">
            {isPro ? <AgentFAQ loginWithRedirect={loginWithRedirect} email={email} /> : <Home loginWithRedirect={loginWithRedirect} />}
          </Route>
        </Switch>
        {
          !isAuthenticated &&
          <div style={{backgroundColor: '#8BC53F', padding: '40px 0px', width: '100%', textAlign: 'center', color: 'white', fontSize: '30px', fontWeight: '700', lineHeight: '56px', justifyContent: 'center', alignItems: 'center'}}>
            Are You a REALTOR®?    
            {isPro ? 
              <Button onClick={() => loginWithRedirect({ screen_hint: 'signup'})} variant="contained" style={{ backgroundColor: 'white', marginLeft: '8px', padding: '10px 40px', color: '#8bc53f', fontSize: '20px', fontWeight: '900', boxShadow: '0 9px 20px -10px black', transform: 'translateY(-4px)'}}>
                Get Started
              </Button> :               
              <Link to="/agents/start">
                <Button variant="contained" style={{ backgroundColor: 'white', marginLeft: '8px', padding: '10px 40px', color: '#8bc53f', fontSize: '20px', fontWeight: '900', boxShadow: '0 9px 20px -10px black', transform: 'translateY(-4px)'}}>
                  Learn More
                </Button>
              </Link>
            }
          </div>
        }
        <Footer />
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth='md'
          PaperProps={{
            style: {
              backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'
            },
          }}
        >
          <DialogTitle>
            <Typography variant="h5" color="primary">We just need a little more info</Typography>
            <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ textAlign: 'center'}}>
              Thanks for your interest in MyBrokerMatch.com.  Please provide your information below and we will redirect you to our REALTOR® Lead Progam Overview. We look forward to sharing the capabilities of our platform with you and what makes our leads different.
              <br/>   
              See you on the other side!
              <br/>
            </Typography>
            <Grid container spacing={1} style={{ width: '400px', marginTop: '16px', marginLeft: 'auto', marginRight: 'auto'}}>
              <Grid item xs={12} style={{ backgroundColor: "white", borderRadius: "5px", margin: "4px" }}>
                <TextField 
                  autoFocus
                  id="firstName"
                  label="First Name"
                  variant="outlined"
                  required
                  size="small"
                  style={{width: "100%" }}
                  onChange={(e) => { setFirstName(e.target.value); }}
                />
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "white", borderRadius: "5px", margin: "4px" }}>
                <TextField 
                  autoFocus
                  id="lastName"
                  label="Last Name"
                  variant="outlined"
                  required
                  size="small"
                  style={{width: "100%" }}
                  onChange={(e) => { setLastName(e.target.value); }}
                />
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "white", borderRadius: "5px", margin: "4px" }}>
                <TextField 
                  autoFocus
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  required
                  size="small"
                  style={{width: "100%" }}
                  onChange={(e) => { setPhone(e.target.value); }}
                />
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "white", borderRadius: "5px", margin: "4px" }}>
                <TextField 
                  autoFocus
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  required
                  size="small"
                  style={{width: "100%" }}
                  onChange={(e) => { setEmail(e.target.value); }}
                />
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "white", borderRadius: "5px", margin: "4px" }}>
                <TextField 
                  autoFocus
                  id="firm"
                  label="Name of Firm"
                  variant="outlined"
                  required
                  size="small"
                  style={{width: "100%" }}
                  onChange={(e) => { setFirm(e.target.value); }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>

          </DialogActions>
        </Dialog>
        <Backdrop open={showLoading} style={{ zIndex: '5000'}}>
          <CircularProgress style={{ color: 'white' }} />
        </Backdrop>        
      </AppContext.Provider>
    </React.Fragment>
  );
}

export default App;
