import { lighten, Dialog, TextField, FormControl, InputLabel, Select, MenuItem, DialogTitle, DialogActions, Button, Grid, Typography, DialogContent, IconButton, makeStyles, TableCell, TableHead, TableRow, Paper, TableContainer, Table, TableBody, InputAdornment } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  targetDate: yup
    .date('Enter Closing date')
    .required('Closing Date is required'),
  propertyAddress: yup
    .string('Enter Property address')
    .required('Property address is required'),
  purchasePrice: yup
    .string('Enter Purchase price')
    .required('Purchase price is required'),
  commission: yup
    .string('Enter commission amount')
    .required('Commission amount is required'),
  closingContactPerson: yup
    .string('Enter name for Closing Contact Person')
    .required('Contact Person name is required'),
  closingEntityName: yup
    .string('Enter name of Closing Entity')
    .required('Closing Entity name is required'),
  closingEntityAddress: yup
    .string('Enter address for Closing Entity')
    .required('Closing Entity address is required'),
  closingEntityPhone: yup
    .string('Enter phone number for Closing Entity')
    .required('Closing Entity phone number is required'),
  closingEntityEmail: yup
    .string('Enter email for Closing Entity')
    .required('Closing Entity email is required'),
  closingEntityType: yup
    .string('Select type for Closing Entity')
    .required('Closing Entity type is required'),

});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name'},
  { id: 'type', label: 'Type'},
  { id: 'email', label: 'E-Mail'},
  { id: 'phone', label: 'Primary Phone'},
  { id: 'status', label: 'Status'},
  { id: 'lastUpdated', label: 'Last Updated'},
]

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));


const ClientTable = ({completed = false}) => {
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [dlgOpen, setDlgOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [matchDlgOpen, setMatchDlgOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      targetDate: '',
      propertyAddress: '',
      purchasePrice: '',
      commission: '',
      closingContactPerson: '',
      closingEntityName: '',
      closingEntityAddress: '',
      closingEntityPhone: '',
      closingEntityEmail: '',
      closingEntityType: '',
      transactionId: '',
      fee: '',
      paidInFull: false,

      currentStep: 0,
      matchId: 0


    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
    },  
  });
  
  useEffect(() => {
    var path = completed ? '/api/v2/History/' : '/api/v2/AgentMatches/';
    axios.get(process.env.REACT_APP_BASE_URL + path + apiUser.id + '?code='+ process.env.REACT_APP_API_KEY + '').then((response) => {
      setRows(response.data);
      setLoaded(true);
    });
  }, [loaded]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    setSelected(row);
    handleSelect(row);
    if(!row.accepted)
    {
      setDlgOpen(true);
    }
    else
    {
      setMatchDlgOpen(true);
    }
  };

  const handleSelect = (row) => {
    formik.values.targetDate = row.targetDate ? row.targetDate ?? '' : '';
    formik.values.propertyAddress = row.propertyAddress ? row.propertyAddress ?? '' : '';
    formik.values.purchasePrice = row.purchasePrice ? row.purchasePrice ?? '' : '';
    formik.values.commission = row.commission ? row.commission ?? '' : '';
    formik.values.closingContactPerson = row.closingContactPerson ? row.closingContactPerson ?? '' : '';
    formik.values.closingEntityName = row.closingEntityName ? row.closingEntityName ?? '' : '';
    formik.values.closingEntityAddress = row.closingEntityAddress ? row.closingEntityAddress ?? '' : '';
    formik.values.closingEntityPhone = row.closingEntityPhone ? row.closingEntityPhone ?? '' : '';
    formik.values.closingEntityEmail = row.closingEntityEmail ? row.closingEntityEmail ?? '' : '';
    formik.values.closingEntityType = row.closingEntityType ? row.closingEntityType ?? '' : '';
    formik.values.currentStep = row.currentFuse ? row.currentFuse : 0;
    formik.values.matchId = row.id ? row.id : 0;
    formik.values.transactionId = row.transactionId ? row.transactionId : '';
    formik.values.fee = row.fee ? row.fee : '0.00';
    formik.values.paidInFull = row.paidInFull;

    setActiveStep(row.currentFuse ?? 0);

  };

  const handleAccept = () => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Matches/Accept/' + selected.id + '?code='+ process.env.REACT_APP_API_KEY + '').then((response) => {
      if (response.status === 200)
      {
        setLoaded(false);
        setDlgOpen(false);
      }
    });

  };

  const handleDeny = () => {
    setDlgOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleUpdate(1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleUpdate(-1);
  };

  const handleUpdate = (nStep) => {
    formik.values.currentStep = activeStep + nStep;
    setShowLoading(true);
    axios({
      method: 'post',
      url: process.env.REACT_APP_BASE_URL + '/api/v2/Users/UpdateMatch?code='+ process.env.REACT_APP_API_KEY + '',
      data: formik.values,
      headers: {
        'Content-Type': 'text/plain'
      }
    })
    .then((response) => 
    {
      formik.values.fee = response.data.fee ? response.data.fee : '0.00';
      formik.values.commission = response.data.commission ? response.data.commission : '0.00';
      formik.values.purchasePrice = response.data.purchasePrice ? response.data.purchasePrice : '0.00'; 
      setIsLoading(!isLoading);
      setShowLoading(false); 
    })
    .catch((error) =>
    {
      console.log(error);
      setShowLoading(false); 
    });
    setShowLoading(false); 


  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
      {rows && rows.length === 0 &&
          <Typography>No matches found.</Typography>
      }
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="matches"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows && stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none" align="center" >
                        {row.firstName + ' ' + row.lastName}
                      </TableCell>
                      <TableCell align="center" >{row.transactionType}</TableCell>
                      <TableCell align="center" >{row.userName}</TableCell>
                      <TableCell align="center" >{row.primaryPhone}</TableCell>
                      <TableCell align="center" >{row.accepted ? (row.paidInFull ? 'Paid' : row.status) : 'New'}</TableCell>
                      <TableCell align="center" >{row.lastUpdated}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * (emptyRows > 5 ? 5 : emptyRows) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[20,50,100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
        <Dialog onClose={() => setDlgOpen(false)} open={dlgOpen} fullWidth={false} maxWidth='md' >
          <DialogTitle>
            <Typography variant="h5" color="primary">Do you accept this match?</Typography>
            <IconButton aria-label="close" onClick={() => setDlgOpen(false)} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} style={{ width: '600px', marginLeft: 'auto', marginRight: 'auto'}}>
              <Grid item xs={12} md={6} style={{ display: 'flex' }} >
                Transaction Type: {selected && selected.transactionType}
              </Grid>
              {selected && selected.transactionType === "Buying" &&
                <Grid item xs={12} md={6} style={{display: 'flex'  }}>
                  Price Range: {selected && selected.priceMinimum} - {selected && selected.priceMaximum}
                </Grid>
              }
              {selected && selected.transactionType === "Selling" &&
                <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                  Asking Price: {selected && selected.askingPrice}
                </Grid>
              }
              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Timeframe: {selected && selected.timeframe}
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Property Type: {selected && selected.propertyType}
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Location: {selected && selected.locationText}
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', marginTop: '16px'  }}>
                <Typography style={{ textAlign: 'center'}}>
                  I understand that by accepting this client I am agreeing to pay a referral fee of 35% of commission on the transaction to MyBrokerMatch.com.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleAccept}>Accept</Button>
            <Button variant="contained" color="primary" onClick={handleDeny}>Deny</Button>
            <Button variant="contained" color="primary" onClick={() => setDlgOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>        
        <Dialog onClose={() => {setMatchDlgOpen(false); setLoaded(false)}} open={matchDlgOpen} fullWidth={false} maxWidth='md' >
          <DialogTitle>
            <Typography variant="h5" color="primary">{selected && selected.transactionType} Client</Typography>
            <IconButton aria-label="close" onClick={() => {setMatchDlgOpen(false); setLoaded(false)}} style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey'}}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
              
              <Grid item xs={12} style={{ display: 'flex' }} >
                <Typography variant="h4" color="primary">{selected && selected.firstName} {selected && selected.lastName}</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <Typography variant="h5" color="primary">{selected && selected.primaryPhone}</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <Typography variant="h5" color="primary">{selected && <a style={{textDecoration: 'none', color: '#1B75BB'}} href={"mailto:"+ selected.userName}>{selected.userName}</a>}</Typography>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex', borderBottom: 'solid'}} >                
              </Grid>
              

              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Location: {selected && selected.locationText}
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Timeframe: {selected && selected.timeframe}
              </Grid>
              

            
              {selected && selected.transactionType === "Buying" &&
                <Grid item xs={12} md={6} style={{display: 'flex'  }}>
                  Price Range: {selected && selected.priceMinimum} - {selected && selected.priceMaximum}
                </Grid>
              }
              {selected && selected.transactionType === "Selling" &&
                <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                  Asking Price: {selected && selected.askingPrice}
                </Grid>
              }
              <Grid item xs={12} md={6} style={{ display: 'flex'  }}>
                Property Type: {selected && selected.propertyType}
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', borderBottom: 'solid'}} >                
              </Grid>

              {activeStep && activeStep < 5 &&
              <Grid item xs={12} style={{ display: 'flex'  }}>
              <Typography variant="h4" color="primary">Client Status</Typography>
              </Grid>              
              }
              
              {activeStep && activeStep < 5 &&
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step key="FirstContact">
                  <StepLabel>Connect with the Client</StepLabel>
                  <StepContent>
                    <Typography>Click 'Next' once you have connected with the Client.</Typography>
                    <div>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
                <Step key="ViewingMarketing">
                  <StepLabel>
                    {selected && selected.transactionType === "Buying" &&
                      "View properties"                  
                    }
                    {selected && selected.transactionType === "Selling" &&
                      "Market the property"                  
                    }
                  </StepLabel>
                  <StepContent>
                    <Typography>
                      {selected && selected.transactionType === "Buying" &&
                        "Click 'Next' once you have started viewing properties."                  
                      }
                      {selected && selected.transactionType === "Selling" &&
                        "Click 'Next' once you have marketed the property."                  
                      }
                    </Typography>
                    <div>
                      <div>
                        <Button
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
                <Step key="Offer">
                  <StepLabel>  
                    {selected && selected.transactionType === "Buying" &&
                      "Make an offer"                  
                    }
                    {selected && selected.transactionType === "Selling" &&
                      "Received an offer"                  
                    }       
                  </StepLabel>
                  <StepContent>
                    <Typography>
                      {selected && selected.transactionType === "Buying" &&
                        "Click 'Next' once you have made an offer."                  
                      }
                      {selected && selected.transactionType === "Selling" &&
                        "Click 'Next' once you have received an offer."                  
                      } 
                    </Typography>
                    <div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
                <Step key="Contract">
                  <StepLabel>
                    Under Contract
                  </StepLabel>
                  <StepContent>
                    <Typography>Once under contract, fill out the form below and click 'Next'</Typography>
                    <div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Confirm Under Contract
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
                <Step key="Closing">
                  <StepLabel>Closing</StepLabel>
                  <StepContent>
                    <Typography>Once closed, confirm all closing information is correct and click 'Confirm Closed'</Typography>
                    <div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={!formik.isValid}
                        >
                          Confirm Closed
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              </Stepper>            
              }
              
              {(activeStep === 3 || activeStep === 4) && (                
                <Grid container spacing={1} style={{ width: '800px', marginLeft: 'auto', marginRight: 'auto'}}>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="targetDate"
                      name="targetDate"
                      label="Target Date"
                      variant="outlined"
                      type="date"
                      required
                      style={{width: '90%'}}
                      value={formik.values.targetDate}
                      onChange={formik.handleChange}
                      error={formik.touched.targetDate && Boolean(formik.errors.targetDate)}  
                      InputLabelProps={{
                        shrink: true,
                      }} 
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="propertyAddress"
                      name="propertyAddress"
                      label="Property Address"
                      variant="outlined"
                      required
                      multiLine={true}
                      rows={3}
                      style={{width: '90%'}}
                      value={formik.values.propertyAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.propertyAddress && Boolean(formik.errors.propertyAddress)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="purchasePrice"
                      name="purchasePrice"
                      label="Purchase Price"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.purchasePrice}
                      onChange={formik.handleChange}
                      error={formik.touched.purchasePrice && Boolean(formik.errors.purchasePrice)}   
                      InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="commission"
                      name="commission"
                      label="Commission"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.commission}
                      onChange={formik.handleChange}
                      error={formik.touched.commission && Boolean(formik.errors.commission)}   
                      InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="closingContactPerson"
                      name="closingContactPerson"
                      label="Closing Contact Person"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.closingContactPerson}
                      onChange={formik.handleChange}
                      error={formik.touched.closingContactPerson && Boolean(formik.errors.closingContactPerson)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="closingEntityName"
                      name="closingEntityName"
                      label="Closing Entity Name"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.closingEntityName}
                      onChange={formik.handleChange}
                      error={formik.touched.closingEntityName && Boolean(formik.errors.closingEntityName)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="closingEntityAddress"
                      name="closingEntityAddress"
                      label="Closing Entity Address"
                      variant="outlined"
                      required
                      multiLine={true}
                      rows={3}
                      style={{width: '90%'}}
                      value={formik.values.closingEntityAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.closingEntityAddress && Boolean(formik.errors.closingEntityAddress)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="closingEntityPhone"
                      name="closingEntityPhone"
                      label="Closing Entity Phone"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.closingEntityPhone}
                      onChange={formik.handleChange}
                      error={formik.touched.closingEntityPhone && Boolean(formik.errors.closingEntityPhone)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <TextField
                      id="closingEntityEmail"
                      name="closingEntityEmail"
                      label="Closing Entity Email"
                      variant="outlined"
                      required
                      style={{width: '90%'}}
                      value={formik.values.closingEntityEmail}
                      onChange={formik.handleChange}
                      error={formik.touched.closingEntityEmail && Boolean(formik.errors.closingEntityEmail)}   
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <FormControl style={{ width: '90%' }} variant="outlined">
                      <InputLabel id="closingEntityTypeLabel" style={{ top: '0px', left: '0px', backgroundColor: 'white'}}>Closing Entity Type *</InputLabel>
                      <Select
                        id="closingEntityType"
                        name="closingEntityType"
                        labelId="closingEntityTypeLabel"
                        variant="outlined"
                        required
                        style={{width: '100%'}}
                        value={formik.values.closingEntityType}
                        onChange={formik.handleChange}
                        error={formik.touched.closingEntityType && Boolean(formik.errors.closingEntityType)}   
                      >
                        <MenuItem value='' disabled><em>Select Type</em></MenuItem>
                        <MenuItem value='Closing Attorney'>Closing Attorney</MenuItem>
                        <MenuItem value='Escrow Agent'>Escrow Agent</MenuItem>
                        <MenuItem value='Title Company'>Title Company</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {activeStep >= 5 &&
                <Grid container spacing={1} style={{ width: '800px', marginLeft: 'auto', marginRight: 'auto'}}>
                  <Typography>
                    <p>Congratulations on another successful transaction!</p>
                    <p>Please provide closing documentation and payment within 14 days of the closed transaction.</p>
                    <p>Documents must be in PDF format and emailed to <a style={{textDecoration: 'none', color: '#1B75BB'}} href="mailto:documents@MyBrokerMatch.com">documents@MyBrokerMatch.com</a>. Include your Transaction Number({formik.values.transactionId}) in the subject line.</p>
                    <p>Your referral fee is 35% of commission. All details below may change once final documentation is confirmed.</p>
                    <p>Transaction Number: {formik.values.transactionId}<br/>
                    Address: {formik.values.propertyAddress}<br/>
                    Close Date: {formik.values.targetDate}<br/>
                    Transaction Amount: $ {formik.values.purchasePrice}<br/>
                    Reported Commission: $ {formik.values.commission}<br/>
                    Calculated Fee: $ {formik.values.fee}<br/>
                    Status: {formik.values.paidInFull ? "Paid" : "Unpaid"}<br/><br/>

                    </p>
                    <p>
                    Mail checks to:<br/>
                    MyBrokerMatch LLC<br/>
                    3061 Trenwest Drive<br/>
                    Winston-Salem, NC 27103<br/><br/>                 
                    </p>
                    <p>
                    Send bank transfers to:<br/>
                    Bank Name:<br/>
                    Bank Address:<br/>
                    Routing Number:<br/>
                    Account Number:<br/>
                    Beneficiary Name:<br/>
                    Beneficiary Address:<br/>
                    </p>
                  </Typography>
                </Grid>
              }






            </Grid>
          </DialogContent>
        </Dialog>
    </div>
  );
}

export default ClientTable;