import { lighten, Dialog, TextField, FormControl, InputLabel, Select, MenuItem, DialogTitle, DialogActions, Button, Grid, Typography, DialogContent, IconButton, makeStyles, TableCell, TableHead, TableRow, TableSortLabel, Paper, TableContainer, Table, TableBody, Divider, TablePagination } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { useFormik } from 'formik';


const AgentMatch = () => {
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const [loaded, setLoaded] = React.useState(false);
  const [processing, setProcessing] = React.useState(true);
  const [matches, setMatches] = React.useState();
  
  useEffect(() => {
    if (!loaded)
    {
      setShowLoading(true);
      axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/ClientMatches/' + apiUser.id + '?code='+ process.env.REACT_APP_API_KEY + '')
        .then((response) => {
          setShowLoading(false);
          if (response.status !== 204)
          {
            setMatches(response.data);
            setProcessing(false);
          }
          setLoaded(true);
        })
        .catch((error) =>{
          setShowLoading(false);
        });
    }
  }, [loaded]);

  return (
    <React.Fragment>
      {
        processing &&    
        <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '400px' }}>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '12px'}}>
            Congratulations!<br/>A Match has been found. Your REALTOR® will contact you within the next 24 hours.
          </Grid>
        </Grid>

      }
      {
        matches && !processing && matches.map((match, i) => (
          <p>
            <Grid container spacing={1} style={{ width: '600px', marginLeft: 'auto', marginRight: 'auto'}}>  
              <Grid item xs={12} style={{ display: 'flex', marginTop: '32px' }} >
                <TextField label="Name" variant="outlined" value={match.name} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <TextField label="Email" variant="outlined" value={match.email} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <TextField label={match.licenseState + " License"} variant="outlined" value={match.licenseId} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <TextField label="Mobile Phone" variant="outlined" value={match.mobilePhone} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} >
                <TextField label="Work Phone" variant="outlined" value={match.workPhone} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', marginBottom: '32px' }} >
                <TextField label="Company Name" variant="outlined" value={match.firmName} InputProps={{ readOnly: true }} style={{width: '90%'}}/>
              </Grid>
            </Grid>
          </p>
        ))
      }
    </React.Fragment>
  );
}

export default AgentMatch;