import { Grid, TextField, Select, MenuItem, Button, InputLabel, FormControl, Tooltip } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  firstName: yup
    .string('Enter your first name')
    .required('First Name is required'),
  lastName: yup
    .string('Enter your last name')
    .required('Last Name is required'),
  streetAddress: yup
    .string('Enter your Street Address')
    .required('Street Address is required'),
  city: yup
    .string('Enter your City')
    .required('City is required'),
  state: yup
    .string('Enter your State')
    .required('State is required'),
  zip: yup
    .string('Enter your ZIP Code')
    .min(5,'Enter your 5 digit ZIP Code')
    .max(5,'Enter your 5 digit ZIP Code')
    .required('ZIP Code is required'),
  homePhone: yup
    .string('Enter your Home Phone Number')
    .min(10,'Enter your 10 digit Home Phone Number')
    .max(10,'Enter your 10 digit Home Phone Number')
    .required('Home Phone Number is required'),
  workPhone: yup
    .string('Enter your Work Phone Number')
    .min(10,'Enter your 10 digit Work Phone Number')
    .max(10,'Enter your 10 digit Work Phone Number'),
  mobilePhone: yup
    .string('Enter your Mobile Phone Number')
    .min(10,'Enter your 10 digit Mobile Phone Number')
    .max(10,'Enter your 10 digit Mobile Phone Number')
});

const EditUser = () => {
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const [userInfo, setUserInfo] = React.useState();
  const [loaded, setLoaded] = React.useState(false);
  
  useEffect(() => {
    if (!loaded)
    {
      var path = '/api/v2/Users/Info/';
      axios.get(process.env.REACT_APP_BASE_URL + path + apiUser.id + '?code='+ process.env.REACT_APP_API_KEY + '').then((response) => {
        setLoaded(true);
        setUserInfo(response.data);
      })
    }
  }, [loaded]);

  useEffect(() => {
    if (loaded)
    {
      formik.setFieldValue('userId', userInfo.userId)
      formik.setFieldValue('firstName', userInfo.firstName);
      formik.setFieldValue('lastName', userInfo.lastName);
      formik.setFieldValue('streetAddress', userInfo.streetAddress);
      formik.setFieldValue('city', userInfo.city);
      formik.setFieldValue('state', userInfo.state);
      formik.setFieldValue('zip', userInfo.zip);
      formik.setFieldValue('homePhone', userInfo.homePhone);
      formik.setFieldValue('workPhone', userInfo.workPhone);
      formik.setFieldValue('mobilePhone', userInfo.mobilePhone);

    }

  }, [userInfo]);

  const formik = useFormik({
    initialValues: {
      userId: '',
      firstName: '',
      lastName: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      homePhone: '',
      workPhone: '',
      mobilePhone: '',
      email: apiUser.username
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      let url = process.env.REACT_APP_BASE_URL + '/api/v2/Users/UpdateUser?code='+ process.env.REACT_APP_API_KEY + '';
 
      axios({
        method: 'post',
        url: url,
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then((response) => 
      {
        setLoaded(false); 
      })
      .catch((error) =>
      {
        console.log(error);
      });
    },
  });


  
  return (
    <React.Fragment>
        <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="streetAddress"
            name="streetAddress"
            label="Street Address"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
            error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="city"
            name="city"
            label="City"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <FormControl style={{ width: '90%' }} variant="outlined">
            <InputLabel id="state-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}}>State *</InputLabel>
            <Select
              id="state"
              name="state"
              labelId="state-input-label"
              variant="outlined"
              required
              style={{width: '100%'}}
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}   
            >
              <MenuItem value='' disabled><em>Select State</em></MenuItem>
              <MenuItem value='AL'>Alabama</MenuItem>
              <MenuItem value='AK'>Alaska</MenuItem>
              <MenuItem value='AZ'>Arizona</MenuItem>
              <MenuItem value='AR'>Arkansas</MenuItem>
              <MenuItem value='CA'>California</MenuItem>
              <MenuItem value='CO'>Colorado</MenuItem>
              <MenuItem value='CT'>Connecticut</MenuItem>
              <MenuItem value='DE'>Delaware</MenuItem>
              <MenuItem value='DC'>District of Columbia</MenuItem>
              <MenuItem value='FL'>Florida</MenuItem>
              <MenuItem value='GA'>Georgia</MenuItem>
              <MenuItem value='HI'>Hawaii</MenuItem>
              <MenuItem value='ID'>Idaho</MenuItem>
              <MenuItem value='IL'>Illinois</MenuItem>
              <MenuItem value='IN'>Indiana</MenuItem>
              <MenuItem value='IA'>Iowa</MenuItem>
              <MenuItem value='KS'>Kansas</MenuItem>
              <MenuItem value='KY'>Kentucky</MenuItem>
              <MenuItem value='LA'>Louisiana</MenuItem>
              <MenuItem value='ME'>Maine</MenuItem>
              <MenuItem value='MD'>Maryland</MenuItem>
              <MenuItem value='MA'>Massachusetts</MenuItem>
              <MenuItem value='MI'>Michigan</MenuItem>
              <MenuItem value='MN'>Minnesota</MenuItem>
              <MenuItem value='MS'>Mississippi</MenuItem>
              <MenuItem value='MO'>Missouri</MenuItem>
              <MenuItem value='MT'>Montana</MenuItem>
              <MenuItem value='NE'>Nebraska</MenuItem>
              <MenuItem value='NV'>Nevada</MenuItem>
              <MenuItem value='NH'>New Hampshire</MenuItem>
              <MenuItem value='NJ'>New Jersey</MenuItem>
              <MenuItem value='NM'>New Mexico</MenuItem>
              <MenuItem value='NY'>New York</MenuItem>
              <MenuItem value='NC'>North Carolina</MenuItem>
              <MenuItem value='ND'>North Dakota</MenuItem>
              <MenuItem value='OH'>Ohio</MenuItem>
              <MenuItem value='OK'>Oklahoma</MenuItem>
              <MenuItem value='OR'>Oregon</MenuItem>
              <MenuItem value='PA'>Pennsylvania</MenuItem>
              <MenuItem value='RI'>Rhode Island</MenuItem>
              <MenuItem value='SC'>South Carolina</MenuItem>
              <MenuItem value='SD'>South Dakota</MenuItem>
              <MenuItem value='TN'>Tennessee</MenuItem>
              <MenuItem value='TX'>Texas</MenuItem>
              <MenuItem value='UT'>Utah</MenuItem>
              <MenuItem value='VT'>Vermont</MenuItem>
              <MenuItem value='VA'>Virginia</MenuItem>
              <MenuItem value='WA'>Washington</MenuItem>
              <MenuItem value='WV'>West Virginia</MenuItem>
              <MenuItem value='WI'>Wisconsin</MenuItem>
              <MenuItem value='WY'>Wyoming</MenuItem>
              <MenuItem value='AS'>American Samoa</MenuItem>
              <MenuItem value='GU'>Guam</MenuItem>
              <MenuItem value='MP'>Northern Mariana Islands</MenuItem>
              <MenuItem value='PR'>Puerto Rico</MenuItem>
              <MenuItem value='VI'>U.S. Virgin Islands</MenuItem>
              <MenuItem value='FM'>Micronesia</MenuItem>
              <MenuItem value='MH'>Marshall Islands</MenuItem>
              <MenuItem value='PW'>Palau</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="zip"
            name="zip"
            label="ZIP Code"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={formik.touched.zip && Boolean(formik.errors.zip)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="homePhone"
            name="homePhone"
            label="Home Phone"
            variant="outlined"
            required
            style={{width: '90%'}}
            value={formik.values.homePhone}
            onChange={formik.handleChange}
            error={formik.touched.homePhone && Boolean(formik.errors.homePhone)}   
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="workPhone"
            name="workPhone"
            label="Work Phone"
            variant="outlined"
            style={{width: '90%'}}
            value={formik.values.workPhone}
            onChange={formik.handleChange}
            error={formik.touched.workPhone && Boolean(formik.errors.workPhone)}  
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            id="mobilePhone"
            name="mobilePhone"
            label="Mobile Phone"
            variant="outlined"
            style={{width: '90%'}}
            value={formik.values.mobilePhone}
            onChange={formik.handleChange}
            error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}  
          />
        </Grid>
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
          <Tooltip title={user.email}>
            <TextField
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              required
              style={{width: '90%'}}
              disabled
              value={formik.values.email}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <Button variant='contained' color='primary' type="submit">
            Submit
          </Button>
        </Grid>
          
      </Grid>
        </form>
    </React.Fragment>
  );
}

export default EditUser;