import { Paper, Checkbox, FormGroup, FormControlLabel, Grid, TextField, Select, MenuItem, Button, InputLabel, FormControl, Tooltip, Typography, Switch, Slider, Radio, RadioGroup, CircularProgress, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useContext } from 'react';
import AppContext from './AppContext';
import { Link } from "react-router-dom";
import Banner from './Banner';
import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

const validationSchema = yup.object({

});

const genders = [
  "Female",
  "Male",
  "Non-Binary"
]

const ethnicities = [
  "Black",
  "East Asian",
  "Hispanic",
  "Native American",
  "Pacific Islander",
  "South Asian",
  "Caucasian"
]

const faiths = [
  "Buddhism",
  "Christianity",
  "Hinduism",
  "Islam",
  "Judaism",
  "Shinto",
  "Sikhism",
  "Unaffiliated"
]

const ages = [
  "Under 30",
  "30-39",
  "40-55",
  "Over 50"
]

const Questionnaire = ({demo = false, disabled = false}) => {
  const { user, apiUser, updateApiUser, isPro, setIsPro, setShowLoading } = useContext(AppContext);
  const history = useHistory();

  const [languages, setLanguages] = React.useState([]);
  const [hobbies, setHobbies] = React.useState([]);
  const [optionsState, setOptionsState] = React.useState({ loading: true });
  const [qDisabled, setQDisabled] = React.useState(disabled);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Languages?code='+ process.env.REACT_APP_API_KEY + '').then((response) => {
      setLanguages(response.data);
    });

    axios.get(process.env.REACT_APP_BASE_URL + '/api/v2/Interests?code='+ process.env.REACT_APP_API_KEY + '').then((response) => {
      setHobbies(response.data);
      setOptionsState({ loading: false});
    });

    if (!apiUser.hasSubscribed)
    {
      axios.post(process.env.REACT_APP_BASE_URL + '/api/v2/Users/SetSubbed?code=' + process.env.REACT_APP_API_KEY + '', { id: apiUser.id }).then((response) => { updateApiUser(); });
    }

  }, []);

  const formik = useFormik({
    initialValues: {
      userid: apiUser && apiUser.id,
      prequalified: false,
      gender: '',
      ethnicity: '',
      faith: '',
      languages: [],
      firstTransaction: false,
      partnerName: '',
      partnerPhone: '',
      partnerEmail: '',
      expectDetermineAfford: false,
      expectArrangeFinancing: false,
      expectDetermineComparableHomePrice: false,
      expectFillingOutPaperwork: false,
      expectNegotiateTerms: false,
      expectFindingRightHome: false,
      expectReferVendors: false,
      expectFindingNeighborhood: false,
      qualityGoodListener: false,
      qualityLocalKnowledge: false,
      qualityHonestyTrust: false,
      qualityReputation: false,
      qualityCommunicationSkills: false,
      qualityResponsiveness: false,
      qualityNegotiatingSkills: false,
      qualityTechnologySkills: false,
      qualityBeingOnTime: false,
      qualitySenseOfHumor: false,
      agentUseTechToCommunicate: 0,
      agentAvailabilityBeyondBusiness: 0,
      agentAttire: 0,
      agentEntireProcess: 0,
      agentNegotiateFairPrice: 0,
      agentHelpAsNeeded: 0,
      agentGetBestPrice: 0,
      personality: '',
      feelStronglyPolitics: false,
      considerMyselfPolitically: '',
      experienceFamiliesChildren: false,
      experienceYoungProfessionals: false,
      experienceMatureAdults: false,
      experienceDisabled: false,
      experienceHorseProperty: false,     
      sensitivityPetDander: false,
      sensitivityPerfume: false,
      sensitivityFlowersPollen: false,
      sensitivityTobacco: false,
      sensitivityMold: false,
      sensitivityFood: false,
      sensitivitySmells: false,
      sensitivityNoise: false,
      sensitivityLight: false,
      wearPerfume: false,
      haveFurredPets: false,
      importantFactor: '',
      seeHousesPerDay: '',
      expectToBeAvailable: '',
      preferGetStartedBy: '',
      smoker: false,
      professionalPreference: '',
      bestTimeOfDay: '',
      cleanHouseRate: 0,
      energyLevel: 0,
      dressThePart: false,
      interests: [],
      preferSameGender: false,
      age: '',
      preferSameEthnicity: false,
      preferSameFaith: false,
      preferUnderstandsFaith: false,

      questionnaireComplete: true,
      detailsComplete: true


    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setQDisabled(true);
      setShowLoading(true);
      if (!isPro)
      {        
        axios({
          method: 'post',
          url: process.env.REACT_APP_BASE_URL + '/api/v2/Users/UpdateClientDetails?code='+ process.env.REACT_APP_API_KEY + '',
          data: values,
          headers: {
            'Content-Type': 'text/plain'
          }
        })
        .catch((error) =>
        {
          console.log(error);
          setShowLoading(false);
        });
      }

      axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + '/api/v2/Users/Languages?code='+ process.env.REACT_APP_API_KEY + '',
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .catch((error) =>
      {
        console.log(error);
        setShowLoading(false);
      });
      
      axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + '/api/v2/Users/Interests?code='+ process.env.REACT_APP_API_KEY + '',
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .catch((error) =>
      {
        console.log(error);
        setShowLoading(false);
      });
      
      axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + '/api/v2/Users/Questionnaire?code='+ process.env.REACT_APP_API_KEY + '',
        data: values,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
      .then((response) =>
      {
        setShowLoading(false);
        history.push('/dashboard');
      })
      .catch((error) =>
      {
        console.log(error);
        setShowLoading(false);
      });   
    },  
  });

  const checkLimitReached = [
    formik.values.qualityGoodListener,
    formik.values.qualityLocalKnowledge,
    formik.values.qualityHonestyTrust,
    formik.values.qualityReputation,
    formik.values.qualityCommunicationSkills,
    formik.values.qualityResponsiveness,
    formik.values.qualityNegotiatingSkills,
    formik.values.qualityTechnologySkills,
    formik.values.qualityBeingOnTime,
    formik.values.qualitySenseOfHumor].filter((v) => v).length >= 5;

  return (
    <React.Fragment>
      <Banner titleText="More About You" />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} style={{ width: '700px', marginLeft: 'auto', marginRight: 'auto', marginTop: '24px', marginBottom: '24px' }}>

            {!isPro &&
            <React.Fragment>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
                <Typography>Have you been prequalified by a lender?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="prequalified" 
                        name="prequalified" 
                        value={formik.values.prequalified}
                        onChange={formik.handleChange}
                        error={formik.touched.prequalified && Boolean(formik.errors.prequalified)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>
            </React.Fragment>
            }
            

            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '48px'}}>
              <Typography>Personal Details:</Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
              <Autocomplete
                id="gender"
                name="gender"
                options={genders}
                freeSolo
                style={{width: '90%'}}
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}  
                renderInput={(params) => (
                  <TextField {...params} label="Gender (Optional)" variant="outlined" />
                )}           
              />
            </Grid>

            {isPro &&
            <React.Fragment>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
              <Autocomplete
                id="age"
                name="age"
                options={ages}
                freeSolo
                style={{width: '90%'}}
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}  
                renderInput={(params) => (
                  <TextField {...params} label="Age (Optional)" variant="outlined" />
                )}           
              />
            </Grid>                  
            </React.Fragment>}            
              
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              {!isPro && <Typography>I need my agent to speak:</Typography>}
              {isPro && <Typography>I speak the following languages.</Typography>}
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center' }}>
              <Autocomplete
                multiple
                id="languages"
                name="languages"
                options={languages}
                style={{width: '90%'}}
                value={formik.values.languages}
                onChange={(e,v) => formik.setFieldValue('languages',v)}
                error={formik.touched.languages && Boolean(formik.errors.languages)}  
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect={true}
                renderInput={(params) => (
                  <TextField {...params} label="Languages" variant="outlined" />
                )}          

              />
            </Grid>
            
            {!isPro &&
            <React.Fragment>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '48px'}}>
                <Typography>Will this be your first real estate transaction?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="firstTransaction" 
                        name="firstTransaction" 
                        value={formik.values.firstTransaction}
                        onChange={formik.handleChange}
                        error={formik.touched.firstTransaction && Boolean(formik.errors.firstTransaction)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>


              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
                <TextField
                  id="partnerName"
                  name="partnerName"
                  label="Spouse/Partner Name (Optional)"
                  variant="outlined"
                  style={{width: '90%'}}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}   
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
                <TextField
                  id="partnerPhone"
                  name="partnerPhone"
                  label="Spouse/Partner Phone (Optional)"
                  variant="outlined"
                  style={{width: '90%'}}
                  value={formik.values.partnerPhone}
                  onChange={formik.handleChange}
                  error={formik.touched.partnerPhone && Boolean(formik.errors.partnerPhone)}   
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
                <TextField
                  id="partnerEmail"
                  name="partnerEmail"
                  label="Spouse/Partner Email (Optional)"
                  variant="outlined"
                  style={{width: '90%'}}
                  value={formik.values.partnerEmail}
                  onChange={formik.handleChange}
                  error={formik.touched.partnerEmail && Boolean(formik.errors.partnerEmail)}   
                />
              </Grid>


              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>What expectations do you have for a real estate professional?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Determining how much home I can afford"
                    control={
                      <Checkbox
                        id="expectDetermineAfford"
                        name="expectDetermineAfford"
                        checked={formik.values.expectDetermineAfford}
                        onChange={formik.handleChange}
                        error={formik.touched.expectDetermineAfford && Boolean(formik.errors.expectDetermineAfford)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Finding and arranging financing"
                    control={
                      <Checkbox
                        id="expectArrangeFinancing"
                        name="expectArrangeFinancing"
                        checked={formik.values.expectArrangeFinancing}
                        onChange={formik.handleChange}
                        error={formik.touched.expectArrangeFinancing && Boolean(formik.errors.expectArrangeFinancing)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Determining what comparable homes sell for in the area"
                    control={
                      <Checkbox
                        id="expectDetermineComparableHomePrice"
                        name="expectDetermineComparableHomePrice"
                        checked={formik.values.expectDetermineComparableHomePrice}
                        onChange={formik.handleChange}
                        error={formik.touched.expectDetermineComparableHomePrice && Boolean(formik.errors.expectDetermineComparableHomePrice)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Filling out paperwork"
                    control={
                      <Checkbox
                        id="expectFillingOutPaperwork"
                        name="expectFillingOutPaperwork"
                        checked={formik.values.expectFillingOutPaperwork}
                        onChange={formik.handleChange}
                        error={formik.touched.expectFillingOutPaperwork && Boolean(formik.errors.expectFillingOutPaperwork)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Negotiate terms and price"
                    control={
                      <Checkbox
                        id="expectNegotiateTerms"
                        name="expectNegotiateTerms"
                        checked={formik.values.expectNegotiateTerms}
                        onChange={formik.handleChange}
                        error={formik.touched.expectNegotiateTerms && Boolean(formik.errors.expectNegotiateTerms)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Finding the right home"
                    control={
                      <Checkbox
                        id="expectFindingRightHome"
                        name="expectFindingRightHome"
                        checked={formik.values.expectFindingRightHome}
                        onChange={formik.handleChange}
                        error={formik.touched.expectFindingRightHome && Boolean(formik.errors.expectFindingRightHome)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Help referring you to vendors (lender, inspector, insurance agent, etc.)"
                    control={
                      <Checkbox
                        id="expectReferVendors"
                        name="expectReferVendors"
                        checked={formik.values.expectReferVendors}
                        onChange={formik.handleChange}
                        error={formik.touched.expectReferVendors && Boolean(formik.errors.expectReferVendors)}   
                      />
                    }
                  />
                  <FormControlLabel
                    label="Finding the right neighborhood"
                    control={
                      <Checkbox
                        id="expectFindingNeighborhood"
                        name="expectFindingNeighborhood"
                        checked={formik.values.expectFindingNeighborhood}
                        onChange={formik.handleChange}
                        error={formik.touched.expectFindingNeighborhood && Boolean(formik.errors.expectFindingNeighborhood)}   
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>Please select the top 5 qualities in a real estate professional that are the most important to you.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Good Listener"
                    control={
                      <Checkbox
                        id="qualityGoodListener"
                        name="qualityGoodListener"
                        checked={formik.values.qualityGoodListener}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityGoodListener && Boolean(formik.errors.qualityGoodListener)}   
                        disabled={checkLimitReached && !formik.values.qualityGoodListener}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Knowledge of the area"
                    control={
                      <Checkbox
                        id="qualityLocalKnowledge"
                        name="qualityLocalKnowledge"
                        checked={formik.values.qualityLocalKnowledge}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityLocalKnowledge && Boolean(formik.errors.qualityLocalKnowledge)}   
                        disabled={checkLimitReached && !formik.values.qualityLocalKnowledge}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Honesty and Trustworthiness"
                    control={
                      <Checkbox
                        id="qualityHonestyTrust"
                        name="qualityHonestyTrust"
                        checked={formik.values.qualityHonestyTrust}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityHonestyTrust && Boolean(formik.errors.qualityHonestyTrust)}   
                        disabled={checkLimitReached && !formik.values.qualityHonestyTrust}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Reputation"
                    control={
                      <Checkbox
                        id="qualityReputation"
                        name="qualityReputation"
                        checked={formik.values.qualityReputation}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityReputation && Boolean(formik.errors.qualityReputation)}   
                        disabled={checkLimitReached && !formik.values.qualityReputation}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Communication Skills"
                    control={
                      <Checkbox
                        id="qualityCommunicationSkills"
                        name="qualityCommunicationSkills"
                        checked={formik.values.qualityCommunicationSkills}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityCommunicationSkills && Boolean(formik.errors.qualityCommunicationSkills)} 
                        disabled={checkLimitReached && !formik.values.qualityCommunicationSkills}  
                      />
                    }
                  />
                  <FormControlLabel
                    label="Responsiveness"
                    control={
                      <Checkbox
                        id="qualityResponsiveness"
                        name="qualityResponsiveness"
                        checked={formik.values.qualityResponsiveness}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityResponsiveness && Boolean(formik.errors.qualityResponsiveness)}   
                        disabled={checkLimitReached && !formik.values.qualityResponsiveness}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Negotiating Skills"
                    control={
                      <Checkbox
                        id="qualityNegotiatingSkills"
                        name="qualityNegotiatingSkills"
                        checked={formik.values.qualityNegotiatingSkills}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityNegotiatingSkills && Boolean(formik.errors.qualityNegotiatingSkills)}   
                        disabled={checkLimitReached && !formik.values.qualityNegotiatingSkills}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Technology Skills"
                    control={
                      <Checkbox
                        id="qualityTechnologySkills"
                        name="qualityTechnologySkills"
                        checked={formik.values.qualityTechnologySkills}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityTechnologySkills && Boolean(formik.errors.qualityTechnologySkills)}  
                        disabled={checkLimitReached && !formik.values.qualityTechnologySkills} 
                      />
                    }
                  />
                  <FormControlLabel
                    label="Being on Time"
                    control={
                      <Checkbox
                        id="qualityBeingOnTime"
                        name="qualityBeingOnTime"
                        checked={formik.values.qualityBeingOnTime}
                        onChange={formik.handleChange}
                        error={formik.touched.qualityBeingOnTime && Boolean(formik.errors.qualityBeingOnTime)}   
                        disabled={checkLimitReached && !formik.values.qualityBeingOnTime}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Good sense of humor"
                    control={
                      <Checkbox
                        id="qualitySenseOfHumor"
                        name="qualitySenseOfHumor"
                        checked={formik.values.qualitySenseOfHumor}
                        onChange={formik.handleChange}
                        error={formik.touched.qualitySenseOfHumor && Boolean(formik.errors.qualitySenseOfHumor)}  
                        disabled={checkLimitReached && !formik.values.qualitySenseOfHumor}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>How important are the following to you, rated 1-5 with 5 being very important.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent's ability to use technology to communicate.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentUseTechToCommunicate"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentUseTechToCommunicate}
                  onChange={(e,value) => formik.setFieldValue('agentUseTechToCommunicate', value)}
                  error={formik.touched.agentUseTechToCommunicate && Boolean(formik.errors.agentUseTechToCommunicate)} 
                />              
              </Grid>   
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s availability to you beyond normal business hours.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentAvailabilityBeyondBusiness"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentAvailabilityBeyondBusiness}
                  onChange={(e,value) => formik.setFieldValue('agentAvailabilityBeyondBusiness', value)}
                  error={formik.touched.agentAvailabilityBeyondBusiness && Boolean(formik.errors.agentAvailabilityBeyondBusiness)} 
                />              
              </Grid>    
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s attire.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentAttire"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentAttire}
                  onChange={(e,value) => formik.setFieldValue('agentAttire', value)}
                  error={formik.touched.agentAttire && Boolean(formik.errors.agentAttire)} 
                />              
              </Grid>     
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s ability to walk you through the entire process of buying a home.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentEntireProcess"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentEntireProcess}
                  onChange={(e,value) => formik.setFieldValue('agentEntireProcess', value)}
                  error={formik.touched.agentEntireProcess && Boolean(formik.errors.agentEntireProcess)} 
                />              
              </Grid>     
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s ability to negotiate a fair purchase price.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentNegotiateFairPrice"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentNegotiateFairPrice}
                  onChange={(e,value) => formik.setFieldValue('agentNegotiateFairPrice', value)}
                  error={formik.touched.agentNegotiateFairPrice && Boolean(formik.errors.agentNegotiateFairPrice)} 
                />              
              </Grid>     
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s ability to help as needed during the process.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentHelpAsNeeded"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentHelpAsNeeded}
                  onChange={(e,value) => formik.setFieldValue('agentHelpAsNeeded', value)}
                  error={formik.touched.agentHelpAsNeeded && Boolean(formik.errors.agentHelpAsNeeded)} 
                />              
              </Grid>     
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>Agent’s ability to get the best possible price, even if it means losing the deal a time or two.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-12px', marginBottom: '36px'}}>
                <Slider
                  id="agentGetBestPrice"
                  defaultValue={3}
                  step={1}
                  marks={[{value: 1, label: 'Not Important'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Very Important'}]}
                  min={1}
                  max={5}
                  valueLabelDisplay="auto"
                  style={{ width: '400px' }}
                  value={formik.values.agentGetBestPrice}
                  onChange={(e,value) => formik.setFieldValue('agentGetBestPrice', value)}
                  error={formik.touched.agentGetBestPrice && Boolean(formik.errors.agentGetBestPrice)} 
                />              
              </Grid>  


              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>In your own opinion, how would you describe your personality.</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="personality" id="personality" value={formik.values.personality} onChange={formik.handleChange} error={formik.touched.personality && Boolean(formik.errors.personality)}>
                    <FormControlLabel value="Strong Willed" control={<Radio />} label="Strong Willed" />
                    <FormControlLabel value="Determined" control={<Radio />} label="Determined" />
                    <FormControlLabel value="Middle of the Road" control={<Radio />} label="Middle of the Road" />
                    <FormControlLabel value="Conflict Sensitive" control={<Radio />} label="Conflict Sensitive" />
                    <FormControlLabel value="Easy Going" control={<Radio />} label="Easy Going" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>Would you like to work with an agent who has special knowledge in the following areas?</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Needs of Families with Children"
                    control={
                      <Checkbox
                        id="experienceFamiliesChildren"
                        name="experienceFamiliesChildren"
                        checked={formik.values.experienceFamiliesChildren}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceFamiliesChildren && Boolean(formik.errors.experienceFamiliesChildren)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of Young Professionals"
                    control={
                      <Checkbox
                        id="experienceYoungProfessionals"
                        name="experienceYoungProfessionals"
                        checked={formik.values.experienceYoungProfessionals}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceYoungProfessionals && Boolean(formik.errors.experienceYoungProfessionals)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of Mature Adults"
                    control={
                      <Checkbox
                        id="experienceMatureAdults"
                        name="experienceMatureAdults"
                        checked={formik.values.experienceMatureAdults}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceMatureAdults && Boolean(formik.errors.experienceMatureAdults)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of the Disabled"
                    control={
                      <Checkbox
                        id="experienceDisabled"
                        name="experienceDisabled"
                        checked={formik.values.experienceDisabled}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceDisabled && Boolean(formik.errors.experienceDisabled)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Horses and Horse Properties"
                    control={
                      <Checkbox
                        id="experienceHorseProperty"
                        name="experienceHorseProperty"
                        checked={formik.values.experienceHorseProperty}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceHorseProperty && Boolean(formik.errors.experienceHorseProperty)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>


              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>Do you or a family member have allergies or sensitivities to the following?</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Pet Dander"
                    control={
                      <Checkbox
                        id="sensitivityPetDander"
                        name="sensitivityPetDander"
                        checked={formik.values.sensitivityPetDander}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityPetDander && Boolean(formik.errors.sensitivityPetDander)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Cologne/Perfume"
                    control={
                      <Checkbox
                        id="sensitivityPerfume"
                        name="sensitivityPerfume"
                        checked={formik.values.sensitivityPerfume}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityPerfume && Boolean(formik.errors.sensitivityPerfume)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Flowers/Pollen"
                    control={
                      <Checkbox
                        id="sensitivityFlowersPollen"
                        name="sensitivityFlowersPollen"
                        checked={formik.values.sensitivityFlowersPollen}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityFlowersPollen && Boolean(formik.errors.sensitivityFlowersPollen)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Tobacco/Smoking"
                    control={
                      <Checkbox
                        id="sensitivityTobacco"
                        name="sensitivityTobacco"
                        checked={formik.values.sensitivityTobacco}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityTobacco && Boolean(formik.errors.sensitivityTobacco)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Mold"
                    control={
                      <Checkbox
                        id="sensitivityMold"
                        name="sensitivityMold"
                        checked={formik.values.sensitivityMold}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityMold && Boolean(formik.errors.sensitivityMold)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Foods"
                    control={
                      <Checkbox
                        id="sensitivityFood"
                        name="sensitivityFood"
                        checked={formik.values.sensitivityFood}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityFood && Boolean(formik.errors.sensitivityFood)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Strong Smells"
                    control={
                      <Checkbox
                        id="sensitivitySmells"
                        name="sensitivitySmells"
                        checked={formik.values.sensitivitySmells}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivitySmells && Boolean(formik.errors.sensitivitySmells)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Loud Noises"
                    control={
                      <Checkbox
                        id="sensitivityNoise"
                        name="sensitivityNoise"
                        checked={formik.values.sensitivityNoise}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityNoise && Boolean(formik.errors.sensitivityNoise)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Bright Light"
                    control={
                      <Checkbox
                        id="sensitivityLight"
                        name="sensitivityLight"
                        checked={formik.values.sensitivityLight}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityLight && Boolean(formik.errors.sensitivityLight)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '36px'}}>
                <Typography>Do you or your spouse/partner normally wear perfume or cologne?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="wearPerfume" 
                        name="wearPerfume" 
                        value={formik.values.wearPerfume}
                        onChange={formik.handleChange}
                        error={formik.touched.wearPerfume && Boolean(formik.errors.wearPerfume)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '36px'}}>
                <Typography>Does anyone in your home have furred pets?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="haveFurredPets" 
                        name="haveFurredPets" 
                        value={formik.values.haveFurredPets}
                        onChange={formik.handleChange}
                        error={formik.touched.haveFurredPets && Boolean(formik.errors.haveFurredPets)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>            
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '36px'}}>
                <Typography>What is the most important factor we should consider in providing you with the best possible match to a real estate professional?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>              
                <FormControl style={{ width: '90%' }} variant="outlined">
                  <InputLabel id="importantFactor-input-label" style={{ top: '0px', left: '0px', backgroundColor: 'white'}}>What matters to you?</InputLabel>
                  <Select
                    id="importantFactor"
                    name="importantFactor"
                    labelId="importantFactor-input-label"
                    variant="outlined"
                    style={{width: '100%'}}
                    value={formik.values.importantFactor}
                    onChange={formik.handleChange}
                    error={formik.touched.importantFactor && Boolean(formik.errors.importantFactor)}   
                  >
                    <MenuItem value='' disabled><em>Make Selection</em></MenuItem>
                    <MenuItem value='personality'>Personality</MenuItem>
                    <MenuItem value='politicalconvictions'>Political Convictions</MenuItem>
                    <MenuItem value='specialneeds'>Accessibility</MenuItem>
                    <MenuItem value='faith'>Faith</MenuItem>
                    <MenuItem value='age'>Age</MenuItem>
                    <MenuItem value='hobbies'>Hobbies</MenuItem>
                    <MenuItem value='smoker'>Smoker/Non-Smoker</MenuItem>
                  </Select>
                </FormControl>
              </Grid>



              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>On any given day, how many houses would you like to see?</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="seeHousesPerDay" id="seeHousesPerDay" value={formik.values.seeHousesPerDay} onChange={formik.handleChange} error={formik.touched.seeHousesPerDay && Boolean(formik.errors.seeHousesPerDay)}>
                    <FormControlLabel value="1 per day" control={<Radio />} label="1 per day" />
                    <FormControlLabel value="4-6 per day" control={<Radio />} label="4-6 per day" />
                    <FormControlLabel value="As many as I can that fit my criteria" control={<Radio />} label="As many as I can that fit my criteria" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>When do you prefer to get started?</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="preferGetStartedBy" id="preferGetStartedBy" value={formik.values.preferGetStartedBy} onChange={formik.handleChange} error={formik.touched.preferGetStartedBy && Boolean(formik.errors.preferGetStartedBy)}>
                    <FormControlLabel value="Early in the AM and finish mid-afternoon" control={<Radio />} label="Early in the AM and finish mid-afternoon" />
                    <FormControlLabel value="Early and work all day until later in the evening" control={<Radio />} label="Early and work all day until later in the evening" />
                    <FormControlLabel value="Later and finish mid-afternoon" control={<Radio />} label="Later and finish mid-afternoon" />
                    <FormControlLabel value="Later and work until later in the evening" control={<Radio />} label="Later and work until later in the evening" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>I prefer to communicate with my agent by:</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="preferToCommunicateBy" id="preferToCommunicateBy" value={formik.values.preferToCommunicateBy} onChange={formik.handleChange} error={formik.touched.preferToCommunicateBy && Boolean(formik.errors.preferToCommunicateBy)}>
                    <FormControlLabel value="Phone" control={<Radio />} label="Phone" />
                    <FormControlLabel value="Email" control={<Radio />} label="Email" />
                    <FormControlLabel value="Text" control={<Radio />} label="Text" />
                    <FormControlLabel value="Skype/Face Time" control={<Radio />} label="Skype/Face Time" />
                  </RadioGroup>
                </FormControl>
              </Grid>           

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
                <Typography>(Optional) If possible, I prefer to work with someone of the same gender as myself.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '8px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="preferSameGender" 
                        name="preferSameGender" 
                        value={formik.values.preferSameGender}
                        onChange={formik.handleChange}
                        error={formik.touched.preferSameGender && Boolean(formik.errors.preferSameGender)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>      

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>(Optional) I prefer to work with an agent that is:</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="age" id="age" value={formik.values.age} onChange={formik.handleChange} error={formik.touched.age && Boolean(formik.errors.age)}>
                    <FormControlLabel value="Under 30" control={<Radio />} label="Under 30" />
                    <FormControlLabel value="30-39" control={<Radio />} label="30-39" />
                    <FormControlLabel value="40-55" control={<Radio />} label="40-55" />
                    <FormControlLabel value="Over 50" control={<Radio />} label="Over 50" />
                  </RadioGroup>
                </FormControl>
              </Grid>                             

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>(Optional) Select or enter your faith:</Typography>
              </Grid> 
              <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center' }}>
                <Autocomplete
                  id="faith"
                  name="faith"
                  options={faiths}
                  freeSolo
                  style={{width: '90%'}}
                  value={formik.values.faith}
                  onChange={formik.handleChange}
                  error={formik.touched.faith && Boolean(formik.errors.faith)}  
                  renderInput={(params) => (
                    <TextField {...params} label="Faith (Optional)" variant="outlined" />
                  )}           
                />
              </Grid>            

              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
                <Typography>(Optional) It is important that an agent understands my faith.</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '8px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="preferUnderstandsFaith" 
                        name="preferUnderstandsFaith" 
                        value={formik.values.preferUnderstandsFaith}
                        onChange={formik.handleChange}
                        error={formik.touched.preferUnderstandsFaith && Boolean(formik.errors.preferUnderstandsFaith)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>        
            </React.Fragment>
            }   

            {isPro &&
            <React.Fragment>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>I have experience or extensive knowledge in the following areas:</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Needs of Families with Children"
                    control={
                      <Checkbox
                        id="experienceFamiliesChildren"
                        name="experienceFamiliesChildren"
                        checked={formik.values.experienceFamiliesChildren}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceFamiliesChildren && Boolean(formik.errors.experienceFamiliesChildren)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of Young Professionals"
                    control={
                      <Checkbox
                        id="experienceYoungProfessionals"
                        name="experienceYoungProfessionals"
                        checked={formik.values.experienceYoungProfessionals}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceYoungProfessionals && Boolean(formik.errors.experienceYoungProfessionals)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of Mature Adults"
                    control={
                      <Checkbox
                        id="experienceMatureAdults"
                        name="experienceMatureAdults"
                        checked={formik.values.experienceMatureAdults}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceMatureAdults && Boolean(formik.errors.experienceMatureAdults)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Needs of the Disabled"
                    control={
                      <Checkbox
                        id="experienceDisabled"
                        name="experienceDisabled"
                        checked={formik.values.experienceDisabled}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceDisabled && Boolean(formik.errors.experienceDisabled)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Horses and Horse Properties"
                    control={
                      <Checkbox
                        id="experienceHorseProperty"
                        name="experienceHorseProperty"
                        checked={formik.values.experienceHorseProperty}
                        onChange={formik.handleChange}
                        error={formik.touched.experienceHorseProperty && Boolean(formik.errors.experienceHorseProperty)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
                <Typography>Do you have allergies or sensitivities to the following?</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormGroup>
                  <FormControlLabel
                    label="Pet Dander"
                    control={
                      <Checkbox
                        id="sensitivityPetDander"
                        name="sensitivityPetDander"
                        checked={formik.values.sensitivityPetDander}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityPetDander && Boolean(formik.errors.sensitivityPetDander)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Cologne/Perfume"
                    control={
                      <Checkbox
                        id="sensitivityPerfume"
                        name="sensitivityPerfume"
                        checked={formik.values.sensitivityPerfume}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityPerfume && Boolean(formik.errors.sensitivityPerfume)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Tobacco/Smoking"
                    control={
                      <Checkbox
                        id="sensitivityTobacco"
                        name="sensitivityTobacco"
                        checked={formik.values.sensitivityTobacco}
                        onChange={formik.handleChange}
                        error={formik.touched.sensitivityTobacco && Boolean(formik.errors.sensitivityTobacco)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '36px'}}>
                <Typography>Does anyone in your home have furred pets?</Typography>
              </Grid>
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
                <Typography>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch 
                        id="haveFurredPets" 
                        name="haveFurredPets" 
                        value={formik.values.haveFurredPets}
                        onChange={formik.handleChange}
                        error={formik.touched.haveFurredPets && Boolean(formik.errors.haveFurredPets)}                        
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </Typography>
              </Grid>          
              
            </React.Fragment>}            

            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>What is your preferred time of day for appointments?</Typography>
            </Grid> 
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl>
                <RadioGroup name="bestTimeOfDay" id="bestTimeOfDay" value={formik.values.bestTimeOfDay} onChange={formik.handleChange} error={formik.touched.bestTimeOfDay && Boolean(formik.errors.bestTimeOfDay)}>
                  <FormControlLabel value="Morning" control={<Radio />} label="Morning" />
                  <FormControlLabel value="Afternoon" control={<Radio />} label="Afternoon" />
                  <FormControlLabel value="Evening" control={<Radio />} label="Evening" />
                </RadioGroup>
              </FormControl>
            </Grid>     
            
              
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>Please indicate which are your favorite hobbies/activities. Select up to 5.</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center' }}>
              <Autocomplete
                multiple
                id="interests"
                name="interests"
                options={hobbies}
                style={{width: '90%'}}
                value={formik.values.interests}
                onChange={(e,v) => formik.setFieldValue('interests',v)}
                error={formik.touched.interests && Boolean(formik.errors.interests)}  
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(options) => (formik.values.interests.length >= 5 ? true : false)}
                disableCloseOnSelect={true}
                renderInput={(params) => (
                  <TextField {...params} label="Activities" variant="outlined" />
                )}          
              />
            </Grid>
                     
              
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              {!isPro && <Typography>I prefer that my agent is a non-smoker.</Typography>}
              {isPro && <Typography>I am a non-smoker.</Typography>}
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
              <Typography>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch 
                      id="smoker" 
                      name="smoker" 
                      value={formik.values.smoker}
                      onChange={formik.handleChange}
                      error={formik.touched.smoker && Boolean(formik.errors.smoker)}                        
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </Typography>
            </Grid>       

            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              {!isPro && <Typography>I prefer an agent with the following personality type:</Typography>}
              {isPro && <Typography>I consider myself to be the following personality type:</Typography>}
            </Grid> 
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginBottom: '48px'}}>
              <FormControl>
                <RadioGroup name="professionalPreference" id="professionalPreference" value={formik.values.professionalPreference} onChange={formik.handleChange} error={formik.touched.professionalPreference && Boolean(formik.errors.professionalPreference)}>
                  <FormControlLabel value="Quiet no-nonsense professional" control={<Radio />} label="Quiet, no-nonsense" />
                  <FormControlLabel value="An outgoing conversationalist" control={<Radio />} label="Outgoing conversationalist" />
                </RadioGroup>
              </FormControl>
            </Grid>
              
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              {!isPro && <Typography>A professional in business should always dress the part.</Typography>}
              {isPro && <Typography>To succeed in business one should always dress the part.</Typography>}
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '48px'}}>
              <Typography>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch 
                      id="dressThePart" 
                      name="dressThePart" 
                      value={formik.values.dressThePart}
                      onChange={formik.handleChange}
                      error={formik.touched.dressThePart && Boolean(formik.errors.dressThePart)}                        
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </Typography>
            </Grid>        
              
            {/* <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>On a scale of 1-5, how clean do you keep your house?</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Slider
                id="cleanHouseRate"
                name="cleanHouseRate"
                defaultValue={3}
                step={1}
                marks={[{value: 1, label: 'Least Clean'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'Most Clean'}]}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                style={{ width: '400px' }}
                value={formik.values.cleanHouseRate}
                onChange={(e,value) => formik.setFieldValue('cleanHouseRate', value)}
                error={formik.touched.cleanHouseRate && Boolean(formik.errors.cleanHouseRate)} 
              />              
            </Grid>             */}
            
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>On a scale of 1-5, rate your usual energy level.</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <Slider
                id="energyLevel"
                name="energyLevel"
                defaultValue={3}
                step={1}
                marks={[{value: 1, label: 'Low Energy'},{value: 2},{value: 3},{value: 4},{value: 5, label: 'High Energy'}]}
                min={1}
                max={5}
                valueLabelDisplay="auto"
                style={{ width: '400px' }}
                value={formik.values.energyLevel}
                onChange={(e,value) => formik.setFieldValue('energyLevel', value)}
                error={formik.touched.energyLevel && Boolean(formik.errors.energyLevel)} 
              />              
            </Grid>       
              
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              {!isPro && <Typography>How available do you want your agent to be?</Typography>}
              {isPro && <Typography>I expect to be available:</Typography>}
            </Grid> 
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl>
                <RadioGroup name="expectToBeAvailable" id="expectToBeAvailable" value={formik.values.expectToBeAvailable} onChange={formik.handleChange} error={formik.touched.expectToBeAvailable && Boolean(formik.errors.expectToBeAvailable)}>
                  <FormControlLabel value="24/7 by phone or text" control={<Radio />} label="24/7 by phone or text" />
                  <FormControlLabel value="8:00 AM to 9:00 PM 7 days a week" control={<Radio />} label="8:00 AM to 9:00 PM 7 days a week" />
                  <FormControlLabel value="8:00 AM to 9:00 PM 6 days a week" control={<Radio />} label="8:00 AM to 9:00 PM 6 days a week" />
                  <FormControlLabel value="8:30 AM to 6:00 PM 5 days a week" control={<Radio />} label="8:30 AM to 6:00 PM 5 days a week" />
                </RadioGroup>
              </FormControl>
            </Grid>


            
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>Do you feel strongly about political issues?</Typography>
            </Grid> 
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '-8px', marginBottom: '8px'}}>
              <Typography>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch 
                      id="feelStronglyPolitics" 
                      name="feelStronglyPolitics" 
                      value={formik.values.feelStronglyPolitics}
                      onChange={formik.handleChange}
                      error={formik.touched.feelStronglyPolitics && Boolean(formik.errors.feelStronglyPolitics)}                        
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
              </Typography>
            </Grid>
            {formik.values.feelStronglyPolitics &&
            <React.Fragment>            
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '0px'}}>
                <Typography>Do you consider yourself:</Typography>
              </Grid> 
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <FormControl>
                  <RadioGroup name="considerMyselfPolitically" id="considerMyselfPolitically" value={formik.values.considerMyselfPolitically} onChange={formik.handleChange} error={formik.touched.considerMyselfPolitically && Boolean(formik.errors.considerMyselfPolitically)}>
                    <FormControlLabel value="Conservative" control={<Radio />} label="Conservative" />
                    <FormControlLabel value="Liberal" control={<Radio />} label="Liberal" />
                    <FormControlLabel value="Moderate" control={<Radio />} label="Moderate" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </React.Fragment>
            }

            
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>The following is meant to help determine how you best work with others in solving a task such as finding and purchasing/selling a home. With each of the following pairs of words, please pick the the best trait in someone with whom you would like to work. If both appear true, pick the one that fits you the most in a situation such as buying a home.</Typography>    
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="factualImaginative" id="factualImaginative" value={formik.values.factualImaginative} onChange={formik.handleChange} error={formik.touched.factualImaginative && Boolean(formik.errors.factualImaginative)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Factual" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Imaginative" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>    
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="structuredFreeFlowing" id="structuredFreeFlowing" value={formik.values.structuredFreeFlowing} onChange={formik.handleChange} error={formik.touched.structuredFreeFlowing && Boolean(formik.errors.structuredFreeFlowing)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Structured" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Free-Flowing" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="logicalDreamer" id="logicalDreamer" value={formik.values.logicalDreamer} onChange={formik.handleChange} error={formik.touched.logicalDreamer && Boolean(formik.errors.logicalDreamer)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Logical" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Dreamer" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>    
            
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="punctualFlexible" id="punctualFlexible" value={formik.values.punctualFlexible} onChange={formik.handleChange} error={formik.touched.punctualFlexible && Boolean(formik.errors.punctualFlexible)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Punctual" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Flexible" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="exactCreative" id="exactCreative" value={formik.values.exactCreative} onChange={formik.handleChange} error={formik.touched.exactCreative && Boolean(formik.errors.exactCreative)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Exact" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Creative" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="seriousEnthusiastic" id="seriousEnthusiastic" value={formik.values.seriousEnthusiastic} onChange={formik.handleChange} error={formik.touched.seriousEnthusiastic && Boolean(formik.errors.seriousEnthusiastic)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Serious" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Enthusiastic" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="organizedSpontaneous" id="organizedSpontaneous" value={formik.values.organizedSpontaneous} onChange={formik.handleChange} error={formik.touched.organizedSpontaneous && Boolean(formik.errors.organizedSpontaneous)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Organized" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Spontaneous" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="focusedFreeSpirited" id="focusedFreeSpirited" value={formik.values.focusedFreeSpirited} onChange={formik.handleChange} error={formik.touched.focusedFreeSpirited && Boolean(formik.errors.focusedFreeSpirited)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Focused" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Free-Spirited" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="ruleFollowerOpenMinded" id="ruleFollowerOpenMinded" value={formik.values.ruleFollowerOpenMinded} onChange={formik.handleChange} error={formik.touched.ruleFollowerOpenMinded && Boolean(formik.errors.ruleFollowerOpenMinded)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Rule follower" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Open-minded" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="plannerCarefree" id="plannerCarefree" value={formik.values.plannerCarefree} onChange={formik.handleChange} error={formik.touched.plannerCarefree && Boolean(formik.errors.plannerCarefree)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Planner" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Carefree" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="cautiousRiskTaker" id="cautiousRiskTaker" value={formik.values.cautiousRiskTaker} onChange={formik.handleChange} error={formik.touched.cautiousRiskTaker && Boolean(formik.errors.cautiousRiskTaker)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Cautious" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Risk-Taker" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="productCenteredIdeaCentered" id="productCenteredIdeaCentered" value={formik.values.productCenteredIdeaCentered} onChange={formik.handleChange} error={formik.touched.productCenteredIdeaCentered && Boolean(formik.errors.productCenteredIdeaCentered)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Product Centered" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Idea Centered" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="traditionalTriesNewThings" id="traditionalTriesNewThings" value={formik.values.traditionalTriesNewThings} onChange={formik.handleChange} error={formik.touched.traditionalTriesNewThings && Boolean(formik.errors.traditionalTriesNewThings)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Traditional" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Tries new things" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="focusesOnPlanKeepOptionsOpen" id="focusesOnPlanKeepOptionsOpen" value={formik.values.focusesOnPlanKeepOptionsOpen} onChange={formik.handleChange} error={formik.touched.focusesOnPlanKeepOptionsOpen && Boolean(formik.errors.focusesOnPlanKeepOptionsOpen)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Focuses on the plan" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Keeps options open" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="readsInstructionsDoesntReadInstructions" id="readsInstructionsDoesntReadInstructions" value={formik.values.readsInstructionsDoesntReadInstructions} onChange={formik.handleChange} error={formik.touched.readsInstructionsDoesntReadInstructions && Boolean(formik.errors.readsInstructionsDoesntReadInstructions)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Reads Instructions" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Doesn't Read Instructions" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="steadyAdventurous" id="steadyAdventurous" value={formik.values.steadyAdventurous} onChange={formik.handleChange} error={formik.touched.steadyAdventurous && Boolean(formik.errors.steadyAdventurous)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Steady" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Adventurous" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="reservedFunLoving" id="reservedFunLoving" value={formik.values.reservedFunLoving} onChange={formik.handleChange} error={formik.touched.reservedFunLoving && Boolean(formik.errors.reservedFunLoving)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Reserved" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Fun loving" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="directiveListener" id="directiveListener" value={formik.values.directiveListener} onChange={formik.handleChange} error={formik.touched.directiveListener && Boolean(formik.errors.directiveListener)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Directive" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Listener" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="initiatorSupportive" id="initiatorSupportive" value={formik.values.initiatorSupportive} onChange={formik.handleChange} error={formik.touched.initiatorSupportive && Boolean(formik.errors.initiatorSupportive)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Initiator" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Supportive" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="doerRelationship" id="doerRelationship" value={formik.values.doerRelationship} onChange={formik.doerRelationship} error={formik.touched.doerRelationship && Boolean(formik.errors.doerRelationship)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Doer" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Relationship focused" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="achieverCaregiver" id="achieverCaregiver" value={formik.values.achieverCaregiver} onChange={formik.handleChange} error={formik.touched.achieverCaregiver && Boolean(formik.errors.achieverCaregiver)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Achiever" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Caregiver" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="drivenEasyGoing" id="drivenEasyGoing" value={formik.values.drivenEasyGoing} onChange={formik.handleChange} error={formik.touched.drivenEasyGoing && Boolean(formik.errors.drivenEasyGoing)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Driven" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Easy Going" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="fastPacedPatient" id="fastPacedPatient" value={formik.values.fastPacedPatient} onChange={formik.handleChange} error={formik.touched.fastPacedPatient && Boolean(formik.errors.fastPacedPatient)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Fast Paced" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Patient" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="opinionatedPeaceMaker" id="opinionatedPeaceMaker" value={formik.values.opinionatedPeaceMaker} onChange={formik.handleChange} error={formik.touched.opinionatedPeaceMaker && Boolean(formik.errors.opinionatedPeaceMaker)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Opinionated" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Peace maker" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="toughPleaser" id="toughPleaser" value={formik.values.toughPleaser} onChange={formik.handleChange} error={formik.touched.toughPleaser && Boolean(formik.errors.toughPleaser)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Tough" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Pleaser" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="selfStarterTeamPlayer" id="selfStarterTeamPlayer" value={formik.values.selfStarterTeamPlayer} onChange={formik.handleChange} error={formik.touched.selfStarterTeamPlayer && Boolean(formik.errors.selfStarterTeamPlayer)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Self-starter" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Team player" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="taskOrientedPeopleCentered" id="taskOrientedPeopleCentered" value={formik.values.taskOrientedPeopleCentered} onChange={formik.handleChange} error={formik.touched.taskOrientedPeopleCentered && Boolean(formik.errors.taskOrientedPeopleCentered)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Task oriented" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="People centered" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="assertiveNonConfrontational" id="assertiveNonConfrontational" value={formik.values.assertiveNonConfrontational} onChange={formik.handleChange} error={formik.touched.assertiveNonConfrontational && Boolean(formik.errors.assertiveNonConfrontational)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Assertive" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Non-confrontational" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="leaderVolunteer" id="leaderVolunteer" value={formik.values.leaderVolunteer} onChange={formik.handleChange} error={formik.touched.leaderVolunteer && Boolean(formik.errors.leaderVolunteer)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Leader" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Volunteer" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="productiveSociable" id="productiveSociable" value={formik.values.productiveSociable} onChange={formik.handleChange} error={formik.touched.productiveSociable && Boolean(formik.errors.productiveSociable)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Productive" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Sociable" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="straightForwardDiplomatic" id="straightForwardDiplomatic" value={formik.values.straightForwardDiplomatic} onChange={formik.handleChange} error={formik.touched.straightForwardDiplomatic && Boolean(formik.errors.straightForwardDiplomatic)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Straightforward" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Diplomatic" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="outcomeDrivenValueDriven" id="outcomeDrivenValueDriven" value={formik.values.outcomeDrivenValueDriven} onChange={formik.handleChange} error={formik.touched.outcomeDrivenValueDriven && Boolean(formik.errors.outcomeDrivenValueDriven)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Outcome driven" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Value driven" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="decisiveCanSeeBothSides" id="decisiveCanSeeBothSides" value={formik.values.decisiveCanSeeBothSides} onChange={formik.handleChange} error={formik.touched.decisiveCanSeeBothSides && Boolean(formik.errors.decisiveCanSeeBothSides)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Decisive" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Can see both sides" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl style={{width: "90%"}}>
                <RadioGroup row name="competitiveCooperative" id="competitiveCooperative" value={formik.values.competitiveCooperative} onChange={formik.handleChange} error={formik.touched.competitiveCooperative && Boolean(formik.errors.competitiveCooperative)}>
                  <Grid item xs={6}><FormControlLabel value="false" control={<Radio />} label="Competitive" /></Grid>
                  <Grid item xs={6}><FormControlLabel value="true" control={<Radio />} label="Cooperative" /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     

            
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>Please consider the following statements in the context of making a significant purchase. Pick the statement from each pairing that describes you best.</Typography>    
            </Grid> 
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="agendaCooperate" id="agendaCooperate" value={formik.values.agendaCooperate} onChange={formik.handleChange} error={formik.touched.agendaCooperate && Boolean(formik.errors.agendaCooperate)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="I tend to set the pace and agenda of a project." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="I like to cooperate with others in coming up with an approach." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="opinionImpressions" id="opinionImpressions" value={formik.values.opinionImpressions} onChange={formik.handleChange} error={formik.touched.opinionImpressions && Boolean(formik.errors.opinionImpressions)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Once I have formed an opinion, I hold to it firmly." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="My opinions and impressions are open to change as I encounter new information or insights from others." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="decisionsInformation" id="decisionsInformation" value={formik.values.decisionsInformation} onChange={formik.handleChange} error={formik.touched.decisionsInformation && Boolean(formik.errors.decisionsInformation)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="I like to make decisions and move forward." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="I prefer to gather as much information as I can before making decisions." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="thoughtsComments" id="thoughtsComments" value={formik.values.thoughtsComments} onChange={formik.handleChange} error={formik.touched.thoughtsComments && Boolean(formik.errors.thoughtsComments)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="I tend to state my thoughts in a straightforward manner." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="I may adjust some of my comments so as to not hurt someone's feelings." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="thoughtCombination" id="thoughtCombination" value={formik.values.thoughtCombination} onChange={formik.handleChange} error={formik.touched.thoughtCombination && Boolean(formik.errors.thoughtCombination)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Major decisions are best made by following a well-thought-out plan." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Major decisions are best made with a combination of gut intuition and factual information." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="numbersEnvisioning" id="numbersEnvisioning" value={formik.values.numbersEnvisioning} onChange={formik.handleChange} error={formik.touched.numbersEnvisioning && Boolean(formik.errors.numbersEnvisioning)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="I prefer using numbers and hard facts in making decisions." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="I prefer envisioning the outcomes of decisions as a part of the process of making them." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="logicalImaginative" id="logicalImaginative" value={formik.values.logicalImaginative} onChange={formik.handleChange} error={formik.touched.logicalImaginative && Boolean(formik.errors.logicalImaginative)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="I tend to be logical and reserved in stressful situations." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="I enjoy having fun and being imaginative even in stressful situations." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="organizedFlexible" id="organizedFlexible" value={formik.values.organizedFlexible} onChange={formik.handleChange} error={formik.touched.organizedFlexible && Boolean(formik.errors.organizedFlexible)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Being well organized helps me to focus on the task at hand." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Being free to be flexible and spontaneous creates energy in a task." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     

            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '64px'}}>
              <Typography>In the following pairs of statements, pick the statement from each pairing that bests finishes this sentence for you, "In an important financial decision, I enjoy working with others who...</Typography>    
            </Grid>  
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="stressfulLighthearted" id="stressfulLighthearted" value={formik.values.stressfulLighthearted} onChange={formik.handleChange} error={formik.touched.stressfulLighthearted && Boolean(formik.errors.stressfulLighthearted)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Remain steady and calm in stressful situations." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Can be lighthearted and imaginative in stressful situations." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="factsPicture" id="factsPicture" value={formik.values.factsPicture} onChange={formik.handleChange} error={formik.touched.factsPicture && Boolean(formik.errors.factsPicture)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Know the facts and can keep track of the details." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Can keep the big picture in balance with data." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', borderBottom: "1px solid #dddddd"}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="paceAvailable" id="paceAvailable" value={formik.values.paceAvailable} onChange={formik.handleChange} error={formik.touched.paceAvailable && Boolean(formik.errors.paceAvailable)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Can set the pace and keep the agenda moving along." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Can be available as they are needed in the process." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>      
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl style={{width: "100%"}}>
                <RadioGroup row name="situationSensitive" id="situationSensitive" value={formik.values.situationSensitive} onChange={formik.handleChange} error={formik.touched.situationSensitive && Boolean(formik.errors.situationSensitive)}>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="false" control={<Radio />} label="Say what they think about a situation." /></Grid>
                  <Grid item xs={6}><FormControlLabel style={{height: "100%"}} value="true" control={<Radio />} label="Are tactful and can be honest as well as sensitive." /></Grid>
                </RadioGroup>
              </FormControl>
            </Grid>     
            
            <Grid item xs={6} md={12} style={{display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
              <Button variant='contained' color='primary' disabled={disabled || qDisabled} type="submit">
                Submit
              </Button>
            </Grid>

          </Grid>
        </form>
    </React.Fragment>
  )
}

export default Questionnaire;