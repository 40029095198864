import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Banner from "./Banner";
import DisclosureText from "./DisclosureText";

const Disclosure = () => {

    return (
      <React.Fragment>
        <Banner titleText="Privacy Policy" />
        <Container maxWidth="md">
          <DisclosureText />          
        </Container>
      </React.Fragment>
    );
};

export default Disclosure;